import moment from "moment";
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import Spline from '@splinetool/react-spline';
import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";


import './index.css';
import { web3 } from '../../store/web3';
import 'react-responsive-modal/styles.css';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { Modal } from 'react-responsive-modal';
import { toFixed } from "../../store/numberFormat";
import { networkId, message } from "../../store/config";
import UnknownLogo from "../../static/images/bookmark.png";
import { toggleLoader, setAddress } from "../../store/actions/Auth";
import { NFTContract,NFTAddress,TokenContract,TokenAddress, TokenStakingContract, TokenStakingAddress, NFTStakingAddress, NFTStakingContract } from '../../store/contract/index';


const Staking = (props) => {

    let [stakeModal, setStakeModal] = useState(false);
    let [stakeModal2, setStakeModal2] = useState(false);
    let [unstakeModal, setUnstakeModal] = useState(false);
    let [unstakeModal2, setUnstakeModal2] = useState(false);

    let [totalStakNFTS, setTotalStakNFTS] = useState([]);
    let [getAllnft, setGetallNFTS] = useState([]);
    let [changeNFTId, setChangeNFTId] = useState();
    let [balanceOf, setBlanaceOf] = useState(0);
    

    let getContractData = async () => {
        if (!props.isLogin) return EventBus.publish('error', `Please connect wallet to perform action`);
        if (!props.publicAddress) return EventBus.publish('error', `Please connect wallet`);
        
        
        /************* Token Staking Data Get ****************/

        let getTotalNFTStaking = await NFTStakingContract.methods.getStakedNFTs(props.publicAddress).call()
        let getAllMintedNFTS = await NFTContract.methods.walletOfOwner(props.publicAddress).call()
        console.log("*************** getAllMintedNFTS",getAllMintedNFTS)
        let result = getAllMintedNFTS.filter(item => !getTotalNFTStaking.includes(item));
        setGetallNFTS(result);
        if(result.length > 0) setChangeNFTId(result[0])
        if(getTotalNFTStaking.length > 0){
            setTotalStakNFTS(getTotalNFTStaking)
            setChangeNFTId(getTotalNFTStaking[0])
        }
        console.log("*************** getTotalNFTStaking",getTotalNFTStaking)
        console.log("*************** result",result)
        
        /************* NFT Staking Data Get ****************/
        
    }

    useEffect(()=>{
        getContractData()
    },[]);


    const stakeNFT = async (e) =>{
        try {

            if (!props.isLogin) return EventBus.publish('error', `Please connect wallet to perform action`);
            if (!changeNFTId) return EventBus.publish('error', `Please set NFT id`);

            if (!props.publicAddress) return EventBus.publish('error', `Please connect wallet`);

            let owneris = await NFTContract.methods.ownerOf(changeNFTId).call();
            console.log("****************** owneris",owneris)
            console.log("****************** props.publicAddress",props.publicAddress)
            if(owneris.toLowerCase() !==  props.publicAddress.toLowerCase()) return EventBus.publish('error', `Invalid owner`); 

            props.toggleLoader({ message: 'Approve for stake NFT...', status: true });

            web3.eth.sendTransaction({
                from: props.publicAddress,
                to: NFTAddress,
                value: 0,
                gas: 3000000,
                data: NFTContract.methods.approve(NFTStakingAddress, changeNFTId).encodeABI()
                   
            }).on('transactionHash', (hash) => console.log(`************ tx =`, hash))
                .on('receipt', async receipt => {
                    props.toggleLoader({ message: 'NFT staking...', status: true });
                    web3.eth.sendTransaction({
                        from: props.publicAddress,
                        to: NFTStakingAddress,
                        value: 0,
                        gas: 3000000,
                        data: NFTStakingContract.methods.stake(changeNFTId).encodeABI()
                           
                    }).on('transactionHash', (hash) => console.log(`************ tx =`, hash))
                        .on('receipt', async receipt => {
                            setChangeNFTId()
                            getContractData()
                            setStakeModal(false)
                            props.toggleLoader({ message: 'Stake not Completed...', status: false });
                            EventBus.publish('success', `NFT Staked`);
                        })
                })
        } catch (e) {
            console.log('********Error = ', e);
            props.toggleLoader({ message: 'Stake not Completed...', status: false });
            EventBus.publish('error', `Unable to Stake NFT`);
        };
    }

    const unStakeNFT = async (e) =>{
        try {

            if (!props.isLogin) return EventBus.publish('error', `Please connect wallet to perform action`);
            if (!changeNFTId) return EventBus.publish('error', `Please set NFT id`);

            if (!props.publicAddress) return EventBus.publish('error', `Please connect wallet`);

            props.toggleLoader({ message: 'NFT unstaking...', status: true });
                    web3.eth.sendTransaction({
                        from: props.publicAddress,
                        to: NFTStakingAddress,
                        value: 0,
                        gas: 3000000,
                        data: NFTStakingContract.methods.withdraw(changeNFTId).encodeABI()
                           
                    }).on('transactionHash', (hash) => console.log(`************ tx =`, hash))
                        .on('receipt', async receipt => {
                            setChangeNFTId()
                            getContractData()
                            setUnstakeModal(false)
                            EventBus.publish('success', `NFT UnStaked`);
                            props.toggleLoader({ message: 'NFT unstaking...', status: false });
                        })
        } catch (e) {
            console.log('********Error = ', e);
            props.toggleLoader({ message: 'Stake not Completed...', status: false });
            EventBus.publish('error', `Unable to Stake NFT`);
        };
    }

    const claimNFTRewards = async (e) =>{
        try {

            if (!props.isLogin) return EventBus.publish('error', `Please connect wallet to perform action`);

            if (!props.publicAddress) return EventBus.publish('error', `Please connect wallet`);

            let  CalculateRemainingDays = await NFTStakingContract.methods.CalculateRemainingDays().call({from:props.publicAddress})
            if(CalculateRemainingDays <= 30) return EventBus.publish('error', `You can claim after 30 days`);
            props.toggleLoader({ message: 'Claim rewards...', status: true });
                    web3.eth.sendTransaction({
                        from: props.publicAddress,
                        to: NFTStakingAddress,
                        value: 0,
                        gas: 3000000,
                        data: NFTStakingContract.methods.claimReward().encodeABI()
                           
                    }).on('transactionHash', (hash) => console.log(`************ tx =`, hash))
                        .on('receipt', async receipt => {
                            setChangeNFTId()
                            getContractData()
                            setUnstakeModal(false)
                            EventBus.publish('success', `Claim rewards`);
                            props.toggleLoader({ message: 'NFT unstaking...', status: false });
                        })
        } catch (e) {
            console.log('********Error = ', e);
            props.toggleLoader({ message: 'Stake not Completed...', status: false });
            EventBus.publish('error', `Unable to Stake NFT`);
        };
    }

    const stakeHBurnToken = async (e) =>{
        try {

            if (!props.isLogin) return EventBus.publish('error', `Please connect wallet to perform action`);

            
            if (!props.publicAddress) return EventBus.publish('error', `Please connect wallet`);

            console.log("**************** props.publicAddress",props.publicAddress)

            console.log("**************** TokenContract",TokenContract)

            let balance = await TokenContract.methods.balanceOf(props.publicAddress).call()
            if(balanceOf <= 0) return EventBus.publish('error', `Amount must be grather the 0`);
            console.log("**************** balanceOf",balanceOf)
            console.log("**************** balance",balance)
            balanceOf = (balanceOf*10**8).toString()
            if (Number(balance) < Number(balanceOf)) return EventBus.publish('error', `You have insufficient Hburn balance`);

            props.toggleLoader({ message: 'Approve for stake HBurn...', status: true });

            web3.eth.sendTransaction({
                from: props.publicAddress,
                to: TokenAddress,
                value: 0,
                gas: 3000000,
                data: TokenContract.methods.approve(TokenStakingAddress, balanceOf).encodeABI()
                   
            }).on('transactionHash', (hash) => console.log(`************ tx =`, hash))
                .on('receipt', async receipt => {
                    props.toggleLoader({ message: 'HBurn staking...', status: true });
                    web3.eth.sendTransaction({
                        from: props.publicAddress,
                        to: TokenStakingAddress,
                        value: 0,
                        gas: 3000000,
                        data: TokenStakingContract.methods.stake(balanceOf).encodeABI()
                           
                    }).on('transactionHash', (hash) => console.log(`************ tx =`, hash))
                        .on('receipt', async receipt => {
                            setStakeModal2(false)
                            EventBus.publish('success', `Staked`);
                            props.toggleLoader({ message: 'NFT unstaking...', status: false });
                        })
                })
        } catch (e) {
            console.log('********Error = ', e);
            props.toggleLoader({ message: 'Stake not Completed...', status: false });
            EventBus.publish('error', `Unable to Stake NFT`);
        };
    }

    const unStakeHBurnToken = async (e) =>{
        try {

            if (!props.isLogin) return EventBus.publish('error', `Please connect wallet to perform action`);

            if (!props.publicAddress) return EventBus.publish('error', `Please connect wallet`);

                props.toggleLoader({ message: 'Token unstaking...', status: true });
                    web3.eth.sendTransaction({
                        from: props.publicAddress,
                        to: TokenStakingAddress,
                        value: 0,
                        data: TokenStakingContract.methods.withdraw().encodeABI()
                           
                    }).on('transactionHash', (hash) => console.log(`************ tx =`, hash))
                        .on('receipt', async receipt => {
                            setUnstakeModal(false)
                            EventBus.publish('success', `unstaked`);
                            props.toggleLoader({ message: 'NFT unstaking...', status: false });
                        })
        } catch (e) {
            console.log('********Error = ', e);
            props.toggleLoader({ message: 'Stake not Completed...', status: false });
            EventBus.publish('error', `Unable to Stake NFT`);
        };
    }

    const claimTokenRewards = async (e) =>{
        try {

            if (!props.isLogin) return EventBus.publish('error', `Please connect wallet to perform action`);

            if (!props.publicAddress) return EventBus.publish('error', `Please connect wallet`);
        
            let  CalculateRemainingDays = await TokenStakingContract.methods.CalculateRemainingDays().call({from:props.publicAddress})
            if(CalculateRemainingDays <= 30) return EventBus.publish('error', `You can claim after 30 days`);
            props.toggleLoader({ message: 'Claim rewards...', status: true });

                    web3.eth.sendTransaction({
                        from: props.publicAddress,
                        to: TokenStakingAddress,
                        value: 0,
                        data: TokenStakingContract.methods.claimReward().encodeABI()
                           
                    }).on('transactionHash', (hash) => console.log(`************ tx =`, hash))
                        .on('receipt', async receipt => {
                            EventBus.publish('success', `Claim rewards`);
                            props.toggleLoader({ message: 'NFT unstaking...', status: false });
                        })
        } catch (e) {
            console.log('********Error = ', e);
            props.toggleLoader({ message: 'Stake not Completed...', status: false });
            EventBus.publish('error', `Unable to Stake NFT`);
        };
    }

    return (
        <div className="wrapper">
            <Navbar />

            <section className="explore staking">
                <div className="container">
                    <div className="inner">
                        <div className="head text-center">
                            <h1>Staking</h1>
                        </div>

                        <div class="collection-wrap row">
                            {/* <div class="col-md-6">
                                <div className="box">
                                    <div className="img-wrap">
                                        <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/Oxburn_NFT-gif.gif" alt=" " />
                                    </div>

                                    <div class="btn-wrap">
                                        <button onClick={() => setStakeModal(true)} className="common-btn" >Stake</button>
                                        <button onClick={() => setUnstakeModal(true)} className="common-btn" >Unstake</button>
                                        <button className="common-btn" onClick={claimNFTRewards}>Claim</button>
                                    </div>

                                    <div class="info-box">
                                        <div>
                                            <p>APY percentage</p>
                                            <p>0.1%</p>
                                        </div>

                                        <div>
                                            <p>Monthly reward</p>
                                            <p>10.5 HBurn</p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            <div class="col-md-6">
                                <div className="box">
                                    <div className="img-wrap">
                                        <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/Oxburn96.png" alt="" />
                                    </div>

                                    <div class="btn-wrap">
                                        <button onClick={() => setStakeModal2(true)} className="common-btn">Stake</button>
                                        <button onClick={() => unStakeHBurnToken(true)} className="common-btn">Unstake</button>
                                        <button className="common-btn" onClick={()=>claimTokenRewards()} >Claim</button>
                                    </div>

                                    <div class="info-box">
                                        <div>
                                            <p>APY percentage</p>
                                            <p>7%</p>
                                        </div>

                                        {/* <div>
                                            <p>Yearly reward</p>
                                            <p>8.0 HBurn</p>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />

              <Modal
                open={stakeModal}
                onClose={() => setStakeModal(false)}
                classNames={{
                    modal: `common-modal`
                }}
                center
            >
                <button className="common-close-btn">
                    <img
                        src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/close-modal.png"
                        onClick={() => setStakeModal(false)}
                    />
                </button>

                <div className="modal-body">
                    <h2>Stake your NFT</h2>

                    <div className='form-group'>
                        {/* <input type="number" onChange={(e)=>setChangeNFTId(e.target.value)} value={changeNFTId} /> */}
                        {/* <input type="number" onChange={(e)=>setChangeNFTId(e.target.value <= 0 ? 1 : e.target.value)} value={changeNFTId} /> */}
                        <select onChange={(e)=>setChangeNFTId(e.target.value)} value={changeNFTId}>
                            {getAllnft.length > 0 && getAllnft.map(item=>
                            <option value={item}>{item}</option>
                             )}
                        </select>
                    </div>

                    <button className="common-btn" onClick={stakeNFT}> Confirm</button>
                    <button className="common-btn" onClick={()=>setStakeModal(false)}> Cancel</button>
                </div>
              </Modal>

              <Modal
                open={stakeModal2}
                onClose={() => setStakeModal2(false)}
                classNames={{
                    modal: `common-modal`
                }}
                center
            >
                <button className="common-close-btn">
                    <img
                        src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/close-modal.png"
                        onClick={() => setStakeModal2(false)}
                    />
                </button>

                <div className="modal-body">
                    <h2>Stake your HBurn Token</h2>

                    <div className='form-group'>
                        <input onChange={(e)=>setBlanaceOf(e.target.value)} value={balanceOf}/>
                    </div>

                    <button className="common-btn" onClick={stakeHBurnToken}> Confirm</button>
                    <button className="common-btn" onClick={()=>setStakeModal2(false)}> Cancel</button>
                </div>
              </Modal>

              <Modal
                open={unstakeModal}
                onClose={() => setUnstakeModal(false)}
                classNames={{
                    modal: `common-modal`
                }}
                center
            >
                <button className="common-close-btn">
                    <img
                        src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/close-modal.png"
                        onClick={() => setUnstakeModal(false)}
                    />
                </button>

                <div className="modal-body">
                    <h2>Unstake your NFT</h2>

                    <div className='form-group'>
                        <select onChange={(e)=>setChangeNFTId(e.target.value)} value={changeNFTId}>
                            {totalStakNFTS.length > 0 && totalStakNFTS.map(item=>
                            <option value={item}>{item}</option>
                             )}
                        </select>
                    </div>

                    <button className="common-btn" onClick={unStakeNFT}> Confirm</button>
                    <button className="common-btn" onClick={()=>setUnstakeModal(false)}> Cancel</button>
                </div>
              </Modal>

              <Modal
                open={unstakeModal2}
                onClose={() => setUnstakeModal2(false)}
                classNames={{
                    modal: `common-modal`
                }}
                center
            >
                <button className="common-close-btn">
                    <img
                        src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/close-modal.png"
                        onClick={() => setUnstakeModal2(false)}
                    />
                </button>

                <div className="modal-body">
                    <h2>Unstake your NFT</h2>

                    <div className='form-group'>
                        <select >
                            <option>BNB</option>
                            <option>ETH</option>
                            <option>AVAX</option>
                            <option>XDC</option>
                        </select>
                    </div>

                    <button className="common-btn"> Confirm</button>
                    <button className="common-btn" onClick={()=>setUnstakeModal2(false)}> Cancel</button>
                </div>
              </Modal>


        </div>
    );
}

const mapDispatchToProps = {
    toggleLoader,
    setAddress
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress, lotteryList, isLogin } = Auth;
    return { publicAddress, lotteryList, isLogin }
};

export default connect(mapStateToProps, mapDispatchToProps)(Staking);