import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import Spline from '@splinetool/react-spline';
import React, { useState, useEffect } from 'react';
import { useInView } from "react-intersection-observer";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "react-router-dom"
import Chart from "react-apexcharts";
import { Modal } from 'react-responsive-modal';
import './index.css';
import { web3 } from '../../store/web3';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { toggleLoader, getLottery, setAddress, whiteListNFT } from "../../store/actions/Auth";
import { NFTABI, LotteryABI, CIFITokenABI } from '../../store/contract/index';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import dngrsdragon from "../../static/images/dngrs-dragon.png";
import scratch from "../../static/images/scratch.png";
import connectorslide from "../../static/images/connectorslide.png";
import ceo from "../../static/images/ceo.png";
import cto from "../../static/images/cto.png";
import coo from "../../static/images/coo.png";
import artist from "../../static/images/3d-artist.png";
import gamedeveloper from "../../static/images/game-developer.png";



const HBurnMarketplace = (props) => {

    const [activeIndex, setActiveIndex] = useState(null);
    let [whitelistModal, setWhitelistModal] = useState(false);
    let [whitelistName, setWhitelistName] = useState("");
    let [whitelistEmail, setWhitelistEmail] = useState("");
    let [whitelistPhone, setWhitelistPhone] = useState("");
    let [discordUsername, setDiscordUsername] = useState("");
    let [twitter, settwitter] = useState("");
    let [whiteListeAddres, setWhiteListAddress] = useState("");
    let [learnMoreModal, setLearnMoreModal] = useState(false);

    const series1 = [15, 30, 25, 10, 20];

    const options1 = {
        chart: {
            type: 'donut',
            width: 500
        },

        labels: ["Seed Round", "Public Sale", "Staking", "DAO Treasury", "Team"],

        colors: ['#9abb07', '#dd343a', '#ed9612', '#104c82', '#9747ff', '#18a2d3'],

        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return val + "%";
            }
        },

        fill: {
            type: 'gradient',
        },

        stroke: {
            width: 0
        },

        plotOptions: {
            pie: {
                donut: {
                    size: "70%"
                },
            }
        },

        tooltip: {
            y: {
                formatter: function (value) {
                    return value + "%";
                }
            }
        }
    }

    const options = {
        chart: {
            type: 'line',
            toolbar: {
                show: false,
            },
        },
        dataLabels: {
            enabled: true,
        },
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        markers: {
            enabled: 'false',
            show: 'false'
        },
        xaxis: {
            type: 'numeric',
            title: {
                text: 'Month',
            },
            labels: {
                formatter: (value) => value.toFixed(0), // Format x-axis labels as integers
            },
        },
        yaxis: {
            title: {
                text: 'Tokens',
            },
            labels: {
                formatter: function (value) {
                    return value + "k";
                }
            },
        },
        grid: {
            show: true,
            borderColor: '#3A404666',
            xaxis: {
                lines: {
                    show: false
                }
            },
        },
        legend: {
            position: 'right',
            verticalAlign: 'middle',
            floating: false,
            offsetX: 0,
            offsetY: 0,
            fontSize: '14px',
            markers: {
                width: 12,
                height: 12,
                radius: 6,
            },
            itemMargin: {
                vertical: 8,
            },
            labels: {
                colors: '#FFFFFF', // Set label colors (optional, depends on your background)
            },
            formatter: (seriesName, opts) => {
                return `<div style="display: flex; align-items: center;">
                    <span style="background-color: ${opts.w.globals.colors[opts.seriesIndex]}; width: 12px; height: 12px; border-radius: 6px; display: inline-block; margin-right: 8px;"></span>
                    ${seriesName}
                  </div>`;
            },
        },
        fill: {
            type: 'gradient',
        },
        colors: ['#9abb07', '#dd343a', '#9747ff', '#FFCA28'], // Colors for each series
    };

    const series = [
        {
            name: 'Seed Round',
            data: [
                { x: 0, y: 32 },
                { x: 2, y: 32 },
                { x: 12, y: 315 },
                { x: 40, y: 315 },
                { x: 48, y: 315 },
            ],
        },
        {
            name: 'Public Sale',
            data: [
                { x: 0, y: 0 },
                { x: 48, y: 630 },
                { x: 48, y: 630 },
                { x: 48, y: 630 },
                { x: 48, y: 630 },
            ],
        },
        {
            name: 'Team',
            data: [
                { x: 0, y: 0 },
                { x: 6, y: 0 },
                { x: 24, y: 420 },
                { x: 40, y: 420 },
                { x: 48, y: 420 },
            ],
        },
    ];

    // Function to handle click and toggle the active class
    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    const faqs = [
        {
            question: 'What is ĦBurn?',
            answer: (
                <>
                    <p>ĦBurn is the native token of the ecosystem built on Hedera, it has deflationary mechanics and community governance along with staking and in-game rewards. Explore a vibrant marketplace where ĦBurn Token serves as the lifeblood of transactions.</p>
                </>
            )
        },
        {
            question: 'How does the token burn mechanism work?',
            answer: (
                <>
                    <p>Every transaction on the ĦBurn incurs .1% burn fee. This means that .1% of the tokens involved in each transaction are permanently destroyed, reducing the total supply and increasing the scarcity and potential value of the remaining tokens. If using our DEX 1% of the tokens will be getting burned. In the Marketplace each asset bought will burn 10% of the price of the asset.</p>
                </>
            )
        },
        {
            question: 'What is the NFT-based game?',
            answer: (
                <>
                    <p>The ĦBurn NFT game allows users to purchase unique NFT characters, feed them tokens to boost their power, and participate in competitions. In Strategy game you can attack other lands and take over and earn higher yields as each land is pegged with .15% of the supply.</p>
                </>
            )
        },
        {
            question: 'How can I participate in the ĦBurn DAO?',
            answer: (
                <>
                    <p>To participate in the ĦBurn DAO, you need to hold ĦBurn tokens or NFTs. As a DAO member, you can propose changes to the protocol, vote on key decisions, and help shape the future of the platform. This ensures that the community has a direct say in the development and direction of ĦBurn.</p>
                </>
            )
        },
        {
            question: 'How can I buy ĦBurn tokens?',
            answer: (
                <>
                    <p>You can purchase ĦBurn tokens during our presale rounds, public sale, and on our very own decentralized exchange (DEX). Detailed information on token sales and distribution can be found on our Tokenomics page of the website.</p>
                </>
            )
        }
    ];


    const submitWhitelistModel = async () => {
        try {

            if (!whitelistName && !whitelistName.trim()) return EventBus.publish('error', `Please enter name`);
            // if (!whitelistEmail && !whitelistEmail.trim()) return EventBus.publish('error', `Please enter valid email`);
            // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            // if (!regex.test(whitelistEmail)) return EventBus.publish('error', `Please enter valid email`);
            // if (!whitelistPhone && !whitelistPhone.trim()) return EventBus.publish('error', `Please enter phone`);
            if (!whiteListeAddres && !whiteListeAddres.trim()) return EventBus.publish('error', `Please enter valid wallet address`);

            props.whiteListNFT({ publicAddress: whiteListeAddres, name: whitelistName, email: whitelistEmail, phone: whitelistPhone, discordUsername, twitter });
            setWhitelistModal(false)

            props.toggleLoader({
                message: "Whitelisting in Progress...",
                status: true,
            });

        } catch (error) {
            props.toggleLoader({
                message: "Transaction in Progress...",
                status: false,
            });
            return EventBus.publish('error', error.message);
        }
    }

    var roadmapSlider = {
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        speed: 12000,
        cssEase: 'linear',
        dots: false,
        infinite: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 3,
                    speed: 14000,
                }
            },
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 2,
                    speed: 16000,
                }
            },

            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    speed: 22000,
                }
            }
        ]
    };

    return (
        <div className="home">
            <Navbar />

            <section className="intro">
                {/* <video loop="true" autoplay="autoplay" controls="false" muted>
                    <source src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/oxburn_trailer_2.mp4" type="video/mp4" />
                </video> */}
                <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/main-banner.jpeg" alt="" />


                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content">
                                <div className="head">
                                    <span className="sub-text-pattern">ĦBurn</span>

                                    <h1 className="text-pattern">Ħederagon</h1>
                                </div>

                                <p>Welcome to the world of Ħederagon, where fantasy meets finance in a thrilling new adventure! In the kingdom of Hederagon, dragons are not just mythical creatures they are the very embodiment of economic power. Ħederagon ecosystem is built on Hedera with a native token ĦBurn.</p>

                                <button onClick={() => setLearnMoreModal(true)} className="dragon-btn" href="">Learn More</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="benefits hederagon">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4">
                            <div className="head">
                                <h2 className="sub-text-pattern">Welcome to Ħederagon</h2>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="img-wrap">
                                <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/dragon.gif" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="content">

                                <ul>
                                    <li className="main">10+ different dragon breeds to choose from </li>
                                    <li className="main">PVP mode (Play to earn)
                                        <ul>
                                            <li>Beat other dragon on 1-1 fights and earn rewards in ĦBurn</li>
                                        </ul>
                                    </li>
                                    <li className="main">Strategy Mode(Loot to earn)
                                        <ul>
                                            <li>Own land and build empire on top it and let your dragons defend your territory</li>
                                            <li>Own dragons to attack other territories and Take over.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="aim" className="about">
                <img className="scratch" src={scratch} alt="" />
                <img className="scratch cen" src={scratch} alt="" />
                <img src={dngrsdragon} alt="" />
                <div className="container">


                    <div className="row">
                        <div className="col-lg-6">
                            <div className="head">
                                <h2 className="sub-text-pattern">What We Aim to Achieve?</h2>
                            </div>

                        </div>

                        <div className="col-lg-6">
                            <div className="content">
                                <p>ĦBurn is the native token of the ecosystem built on Hedera, it has deflationary mechanics and community governance along with staking and in-game rewards. Explore a vibrant marketplace where ĦBurn Token serves as the lifeblood of transactions. From staking to exclusive NFT offerings and decentralized exchange, ĦBurn Token is the gateway to a wide range of DeFi, Governance and play to earn opportunities.
                                </p>

                                <ul>
                                    <li>Higher Demand will have higher supply shock with burning mechanism </li>
                                    <li>Play to Earn and loot Game economy </li>
                                    <li>Exclusive Staking for Land owners in the game </li>
                                    <li>Staking for early token holders</li>
                                    <li>Staking for Partners NFT holders</li>
                                    <li>Deflationary DEX for enhancing burn mechanics</li>
                                    <li>DAO Governance with burning effect on each vote using ĦBurn Token.</li>
                                    <li>Marketplace of Dragons</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="security">
                <div className="container">
                    <div className="head text-center">
                        <h2 className="text-pattern">Major Modules</h2>
                    </div>

                    <div className="row">
                        <div className="col-xl-6 col-lg-6">
                            <div className="security-card">
                                <div className="content">
                                    <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/card-1.png" alt="" />

                                    <h3>Deflationary Model</h3>

                                    {/* <p>Every trade burns .5% of the transaction which ultimately reduces supply and boosts token value.</p> */}

                                    <p>Upon Swapping your Hedera to ĦBurn token 1% of the token will be burned. Users will be contributing a gas fee of 1% to contribute to the burning mechanism.</p>

                                    <p>Upon each NFT bought on the marketplace 20% of tokens will be burned.</p>

                                    <p>For having a breath fire effect to attack or defend dragons need to be fed with ĦBurn Token which at backend will be 100% burned.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6 col-lg-6">
                            <div className="security-card">
                                <div className="content">
                                    <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/nft-gaming.png" alt="" />

                                    <h3>Exciting NFT Game</h3>

                                    <p>Purchase unique NFT Dragons using ĦBurn and participate in PVP Battles. Grab your place on the leaderboard to win exciting awards or loot other players' lands.</p>

                                    <p>Purchase Land in Metaverse using ĦBurn in the form of NFT and build your empire on it.</p>

                                    <p>Purchase Land in Metaverse in the form of NFT.</p>

                                    <p>Attack other territories and own more land earn more yields by staking those lands.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6 col-lg-6">
                            <div className="security-card">
                                <div className="content">
                                    <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/governing-body.png" alt="" />

                                    <h3>Governing Body</h3>

                                    <p>ĦBurns partners NFT will be the core of the project which will decide all strategic moves to be made for the future allocation and development with 10% supply of DAO treasury.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6 col-lg-6">
                            <div className="security-card">
                                <div className="content">
                                    <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/nft-gaming.png" alt="" />

                                    <h3>Staking</h3>

                                    <p>Staking for Token holders 8% supply allocation.</p>

                                    <p>Staking for Land owners 15% supply allocation.</p>

                                    <p>Staking for NFT partners NFT holders 2% supply allocation.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="two-wrap">
                <section className="benefits">
                    <div className="container">
                        <div className="inner">
                            <div className="head text-center">
                                <h2 className="text-pattern">NFT Benefits</h2>
                            </div>

                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="content">
                                        <ul>
                                            <li>Total supply 50 NFTs</li>
                                            <li>Stake to earn rewards in ĦBurn </li>
                                            <li>Governing seat in DAO with 0.5% voting power </li>
                                            <li>Rights to propose and Vote</li>
                                            <li>Airdrop of 1 land NFT</li>
                                            <li>Airdrop of 2 Hederagon Dragon NFTs</li>
                                            <li>0.5% revenue sharing for each NFT holder</li>
                                            <li>500 Hburns Token claimable after 2 months of TGE</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-lg-5">
                                    <div className="img-wrap text-right">
                                        <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/partners-nft.gif" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="btn-wrap">
                            {/* <Link to="/#" className="common-btn" >Mint Now <br />(COMING SOON) <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/mint.png" alt="" /></Link> */}
                            <Link to="/whitelist" className="dragon-btn mt-5"> Get whitelisted </Link>
                            {/* <Link to="/mint-nft" className="common-btn" >Mint Now <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/mint.png" alt="" /></Link> */}

                        </div>
                    </div>
                </section>

                <section id="roadmap" className="roadmap">
                    <div className="road-container">
                        <div className="head mb-5 text-center">
                            <h2 className="text-pattern">Roadmap</h2>
                        </div>

                        <Slider className="phases" {...roadmapSlider}>
                            <div className="phase">
                                <div className="inner">
                                    <div>
                                        <span>Q2 2024</span>
                                        <div className="connect-wrap">
                                            <img className="connector" src={connectorslide} alt="" />
                                        </div>
                                        <h3>Foundation</h3>
                                    </div>

                                    <p><b>Token Creation:</b> Develop the ĦBurn token with a clear and fair distribution plan.</p>
                                    <p><b>DEX Development:</b> Develop gasless DEX to have full effect of burning mechanics</p>
                                    <p><b>Game Development:</b> Designing all major characters and environment of the game.</p>
                                    <p><b>Marketplace Development</b></p>
                                </div>
                            </div>

                            <div className="phase">
                                <div className="inner">
                                    <div>
                                        <span>Q3 2024</span>
                                        <div className="connect-wrap">
                                            <img className="connector" src={connectorslide} alt="" />
                                        </div>
                                        <h3>Getting Started</h3>
                                    </div>

                                    <p>DAO Development with burning mechanics on each vote.</p>
                                    <p>Game Logic development and integration of NFTs.</p>
                                    <p>Staking Development</p>
                                    <p><b>Seed Round:</b>Conduct the Seed round with 15% of tokens.</p>
                                    <p><b>ĦBurn Partners:</b> Release the ĦBurn Partners NFT with a $1000 price each</p>
                                </div>
                            </div>

                            <div className="phase">
                                <div className="inner">
                                    <div>
                                        <span>Q4 2024</span>
                                        <div className="connect-wrap">
                                            <img className="connector" src={connectorslide} alt="" />
                                        </div>
                                        <h3>Growth</h3>
                                    </div>

                                    <p><b>DEX launch:</b> Launching DEX with 3% token supply to swap their Hedera tokens to ĦBurn.</p>
                                    <p><b>DAO Launch:</b> Establish the ĦBurn DAO and enable community-driven proposals and voting.</p>
                                    <p><b>Marketplace Launch:</b> For players to start buying pieces of lands and dragons</p>
                                    <p>Staking feature launch for Partners NFT holders, Token Holders and Land owners.</p>
                                    <p><b>Game Launch:</b> PVP mode</p>
                                    <p>IEO with listing on MEXC Exchange</p>
                                </div>
                            </div>

                            <div className="phase">
                                <div className="inner">
                                    <div>
                                        <span>Q1 2025</span>
                                        <div className="connect-wrap">
                                            <img className="connector" src={connectorslide} alt="" />
                                        </div>
                                        <h3>Expansion</h3>
                                    </div>

                                    <p><b>Game launch:</b> Strategy Mode</p>
                                    <p><b>Platform Improvements:</b> Continuously update and improve the DEX, NFT marketplace, and game based on community feedback and technological advancements.</p>
                                    <p><b>Global Outreach:</b> Expand our marketing efforts globally to attract a diverse and active user base.</p>
                                </div>
                            </div>

                            <div className="phase">
                                <div className="inner">
                                    <div>
                                        <span>Q2 2025</span>
                                        <div className="connect-wrap">
                                            <img className="connector" src={connectorslide} alt="" />
                                        </div>
                                        <h3>Maturity </h3>
                                    </div>

                                    <p>Game logic around Land owners can lose their lands upon getting attacked by other players</p>
                                    <p>Strategic Partnerships</p>
                                    <p>In game Wallet</p>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </section>
            </div>

            <div className="two-wrap charts">
                <section id="tokenomics" className="tokenomics">
                    <div className="container">
                        <div className="head">
                            <h2 className="text-pattern">Tokenomics</h2>
                        </div>

                        <div className="inner">
                            <div className="table-wrap">
                                <table>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Token Allocation Category</th>
                                            <th>Percentage of Supply</th>
                                            <th>No. of Tokens (from 2,100,000)</th>
                                            <th>Price (USD)</th>
                                            <th>Vesting</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/public-sale.png" /></td>
                                            <td data-label="Token Allocation Category">Seed Round <br /> (Q3 2024)</td>
                                            <td data-label="Percentage of Supply">15%</td>
                                            <td data-label="No. of Tokens (from 2,100,000)">315k</td>
                                            <td data-label="Price (USD)">10ℏ</td>
                                            <td data-label="Vesting">10% at TGE then 2 months cliff then linear vesting for 10 months</td>
                                        </tr>

                                        {/* <tr>
                                            <td><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/private-sale.png" /></td>
                                            <td>Private Sale<br /> (Q4 2024)</td>
                                            <td>10%</td>
                                            <td>100k</td>
                                            <td>TBA</td>
                                            <td>6 months cliff then linear vesting for 1.5 years</td>
                                        </tr> */}

                                        <tr>
                                            <td><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/airdrop.png" /></td>
                                            <td data-label="Token Allocation Category">Public Sale <br />(Q4 2024)</td>
                                            <td data-label="Percentage of Supply">30%</td>
                                            <td data-label="No. of Tokens (from 2,100,000)">630k</td>
                                            <td data-label="Price (USD)">TBA</td>
                                            <td data-label="Vesting">Linear vesting in 4 year</td>
                                        </tr>

                                        <tr>
                                            <td><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/staking.png" /></td>
                                            <td data-label="Token Allocation Category">Staking</td>
                                            <td data-label="Percentage of Supply">25%</td>
                                            <td data-label="No. of Tokens (from 2,100,000)">525k</td>
                                            <td data-label="Price (USD)"></td>
                                            <td data-label="Vesting">Linear vesting</td>
                                        </tr>

                                        <tr>
                                            <td><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/team.png" /></td>
                                            <td data-label="Token Allocation Category">DAO Treasury</td>
                                            <td data-label="Percentage of Supply">10%</td>
                                            <td data-label="No. of Tokens (from 2,100,000)">210k</td>
                                            <td data-label="Price (USD)"></td>
                                            <td data-label="Vesting">On proposed terms</td>
                                        </tr>

                                        <tr>
                                            <td><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/dao-treasury.png" /></td>
                                            <td data-label="Token Allocation Category">Team</td>
                                            <td data-label="Percentage of Supply">20%</td>
                                            <td data-label="No. of Tokens (from 2,100,000)">420k</td>
                                            <td data-label="Price (USD)"></td>
                                            <td data-label="Vesting">6 months cliff then linear vesting for 1.5 years</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="chart-wrap">
                                {/* <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/tokenomics-chart.png" /> */}

                                <div className="content">
                                    <h3>2.1 Million</h3>
                                    <span>SUPPLY</span>
                                </div>

                                <Chart options={options1} series={series1} type="donut" height={500} />

                            </div>
                        </div>
                    </div>
                </section>

                <section className="vesting-chart">
                    <div className="container">
                        <div className="inner">
                            <Chart options={options} series={series} type="line" height={500} />
                        </div>

                        <div className="content">
                            <p>It's a non cumulative vesting chart.</p>
                            {/* <p>*Tokens are in 000's</p> */}
                            <p>*DAO Treasury and staking vesting will be dependent on the demand</p>
                        </div>
                    </div>
                </section>
            </div>


            {/* <section className="game">
                <video loop="true" autoplay="autoplay" controls="false" muted>
                    <source src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/birdeye.mp4" type="video/mp4" />
                </video>

                <div className="container">
                    <div className="inner">
                        <h2 className="sub-text-pattern">GAME MODES</h2>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum viverra mi eu erat porttitor vulputate. Mauris porta aliquam magna in egestas. Proin ac tempus mauris. Suspendisse sed lacinia ipsum, quis gravida est. Mauris ac congue urna. Nullam quam risus, lacinia vitae sodales at, pharetra id purus.</p>
                    </div>
                </div>
            </section> */}

            {/* <section className="ecosystem">
                <div className="container">
                    <div className="head">
                        <h2 className="text-pattern">ECOSYSTEM</h2>
                    </div>

                    <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/ecosystem.png" alt="" />

                </div>
            </section> */}

            {/* <section className="blogs">
                <div className="container">
                    <div className="head">
                        <span>Reports</span>

                        <h2>Blogs</h2>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <article>
                                <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/blog-img.png" alt="" />
                                
                                <div className="content">
                                    <h3>Morbi eget tortor tristiqu</h3>

                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque lectus augue, ti</p>
                                </div>
                            </article>
                        </div>

                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <article>
                                <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/blog-img.png" alt="" />
                                
                                <div className="content">
                                    <h3>Morbi eget tortor tristiqu</h3>

                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque lectus augue, ti</p>
                                </div>
                            </article>
                        </div>

                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <article>
                                <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/blog-img.png" alt="" />
                                
                                <div className="content">
                                    <h3>Morbi eget tortor tristiqu</h3>

                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque lectus augue, ti</p>
                                </div>
                            </article>
                        </div>

                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <article>
                                <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/blog-img.png" alt="" />
                                
                                <div className="content">
                                    <h3>Morbi eget tortor tristiqu</h3>

                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque lectus augue, ti</p>
                                </div>
                            </article>
                        </div>

                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <article>
                                <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/blog-img.png" alt="" />
                                
                                <div className="content">
                                    <h3>Morbi eget tortor tristiqu</h3>

                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque lectus augue, ti</p>
                                </div>
                            </article>
                        </div>

                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <article>
                                <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/blog-img.png" alt="" />
                                
                                <div className="content">
                                    <h3>Morbi eget tortor tristiqu</h3>

                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque lectus augue, ti</p>
                                </div>
                            </article>
                        </div>

                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <article>
                                <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/blog-img.png" alt="" />
                                
                                <div className="content">
                                    <h3>Morbi eget tortor tristiqu</h3>

                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque lectus augue, ti</p>
                                </div>
                            </article>
                        </div>

                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <article>
                                <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/blog-img.png" alt="" />
                                
                                <div className="content">
                                    <h3>Morbi eget tortor tristiqu</h3>

                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque lectus augue, ti</p>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section> */}
            <section className="team partners">
                <div className="container">
                    <h2 class="text-pattern">Our Partners</h2>

                    <div className="row justify-content-center">
                        <div className="col-xl-3 col-md-4 col-sm-6 text-center">
                            <a href="https://seecoin.io/" className="partner" target="_blank">
                               <img src="https://seecoin.io/build/assets/img/logo_menu.svg" alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="join-us">
                <div className="container">
                    <div className="content">
                        <h2 className="text-pattern">Join the ĦBurn Community</h2>

                        <p>Become a part of the ĦBurn ecosystem and contribute to a decentralized future. Whether you're a trader, investor, or DeFi enthusiast, ĦBurn offers opportunities for everyone.</p>
                    </div>
                </div>
            </section>

            <section className="team">
                <div className="container">
                    <h2 class="text-pattern">Core Team</h2>

                    <div className="row justify-content-center">
                        <div className="col-xl-2 col-md-4 col-sm-6">
                            <div className="team-member">
                                <img src={ceo} alt="CEO" title="CEO"/>

                                <h3>Khizar Sattar</h3>

                                <p>CEO</p>
                            </div>
                        </div>

                        <div className="col-xl-2 col-md-4 col-sm-6">
                            <div className="team-member">
                                <img src={coo} alt="COO" title="COO"/>

                                <h3>Taimoor Malik</h3>

                                <p>COO</p>
                            </div>
                        </div>

                        <div className="col-xl-2 col-md-4 col-sm-6">
                            <div className="team-member">
                                <img src={cto} alt="CTO" title="CTO"/>

                                <h3>Muhammad Bilal</h3>

                                <p>CTO</p>
                            </div>
                        </div>

                        <div className="col-xl-2 col-md-4 col-sm-6">
                            <div className="team-member">
                                <img src={artist} alt="3d-Artist" title="3d-Artist"/>

                                <h3>Rustam Rehman</h3>

                                <p>3d Artist</p>
                            </div>
                        </div>

                        <div className="col-xl-2 col-md-4 col-sm-6">
                            <div className="team-member">
                                <img src={gamedeveloper} alt="Head-of-Game-Dev" title="Head-of-Game-Dev"/>

                                <h3>Muhammad Afzal</h3>

                                <p>Head of Game Developer</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="faqs" className="faqs">
                <div className="container">
                    <div className="head text-center">
                        <h2 className="text-pattern">FAQs</h2>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="content">
                                {faqs.map((faq, index) => (
                                    <div>
                                        <h3 key={index} className={`${index === activeIndex ? 'show' : ''}`}
                                            onClick={() => handleClick(index)}>
                                            {faq.question}
                                        </h3>

                                        {index === activeIndex && <div className="body">{faq.answer}</div>}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal
                open={whitelistModal}
                onClose={() => setWhitelistModal(false)}
                classNames={{
                    modal: `common-modal`
                }}
                center
            >
                <button className="common-close-btn">
                    <img
                        src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/close-modal.png"
                        onClick={() => setWhitelistModal(false)}
                    />
                </button>

                <div className="modal-body">
                    <h2>Whitelist For Partner NFT</h2>

                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Name"
                            onChange={e => setWhitelistName(e.target.value)}
                            defaultValue={whitelistName}
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="email"
                            placeholder="Email"
                            onChange={e => setWhitelistEmail(e.target.value)}
                            defaultValue={whitelistEmail}
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="tel"
                            placeholder="Phone"
                            onChange={e => setWhitelistPhone(e.target.value)}
                            defaultValue={whitelistPhone}
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="X Username"
                            onChange={e => settwitter(e.target.value)}
                            defaultValue={twitter}
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Discord Username"
                            onChange={e => setDiscordUsername(e.target.value)}
                            defaultValue={discordUsername}
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Hashpack Wallet Address"
                            onChange={e => setWhiteListAddress(e.target.value)}
                            defaultValue={whiteListeAddres}
                        />
                    </div>

                    <button className="common-btn pr" onClick={submitWhitelistModel} > Submit Now <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/buy.png" /></button>
                </div>
            </Modal>

            <Modal
                open={learnMoreModal}
                onClose={() => setLearnMoreModal(false)}
                classNames={{
                    modal: `common-modal learn-more-modal`
                }}
                center
            >
                <button className="common-close-btn">
                    <img
                        src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/close-modal.png"
                        onClick={() => setLearnMoreModal(false)}
                    />
                </button>

                <div className="modal-body">
                    <p>As you venture through our ecosystem, you'll discover that ĦBurn is the heart of Ħederagon. It powers the entire kingdom, drives economic activities, enables decentralized governance, and offers unique financial opportunities through its deflationary mechanics. With a total supply of just 2.1 million tokens, every transaction within the Ħederagon ecosystem further enhances the scarcity and value of ĦBurn, making it a crucial asset for all participants.
                    </p>

                    <p>In the vibrant world of Ħederagon, the dragons' strength is intertwined with the economy which means strategic decisions can lead to massive rewards. Whether you're engaging in fierce PVP battles or devising clever strategies in the loot-based mode, ĦBurn is your gateway to unlock the full potential of this immersive play-to-earn experience.
                    </p>

                    <p>So, as you step into Ħederagon, remember that your actions have real financial consequences. The power you wield with ĦBurn can define your success. Explore, compete, and rise to the top in a world where fantasy and finance combine seamlessly.</p>

                    <p>Let’s break down the major modules to understand the Ħederagon ecosystem better:</p>

                    <h3>Deflationary Model</h3>

                    <p>As you engage with ĦBurn, every transaction you make is a step toward increasing the value of your holdings. With 0.1% of ĦBurn tokens burned on every transaction and an additional 1% burned on all swaps, you're directly contributing to the scarcity and value of ĦBurn.</p>

                    <p>In our immersive game, 10% of the tokens used to acquire game assets are burned, and when you feed your dragons to enhance their abilities, 100% of the tokens used are burned, further amplifying the deflationary power of ĦBurn. Additionally, participating in our DAO governance requires 1 ĦBurn token to vote, which is also burned entirely. This reflects our unwavering commitment to preserving and enhancing the value of ĦBurn for the entire community.</p>

                    <h3>Exciting NFT Game</h3>

                    <p>In our exciting gaming ecosystem, NFT dragons are more than just collectibles—they're your key to strategic dominance. You can either engage in thrilling PvP battles or in strategy mode to rise through the ranks and claim exclusive rewards. With ĦBurn tokens, you can also acquire and develop NFT-based lands in the Metaverse to own an empire that reflects your strategic prowess. </p>

                    <p>Every land purchase and upgrade with ĦBurn tokens not only elevates your influence but also fuels the deflationary mechanism of the token by burning significant portions in transactions. This not only strengthens the in-game economy but also secures your place as a powerful player in this dynamic world.</p>

                    <h3>Governing Body</h3>

                    <p>As a valued partner in our DAO, you hold the power to shape the future of our ecosystem. With 10% of the DAO's treasury at your disposal, your influence is significant in guiding the direction of Ħederagon. DAO proposals can be created only by the team and ĦBurn Partner NFT holders. </p>

                    <p>The voting allocation of the DAO is divided among three groups: Partners (25%), Team (35%), and Community (40%). Proposals require a strong consensus, with 60% of votes needed for approval, ensuring that every decision benefits the entire community and that at least two parties need to strongly agree to pass a proposal, making the governance structure fairly decentralized.</p>

                    <p>As a holder of Partner NFTs, you play a crucial role in driving key strategies and developments to reinforce our commitment to a truly decentralized governance structure where your voice matters.</p>

                    <h3>Staking</h3>

                    <p>You can discover the staking opportunities that await you in the Ħederagon ecosystem, tailored to reward those who believe in the long-term vision. Our staking mechanisms are designed to enhance your earning potential while securing the network. We offer two types of staking:</p>

                    <ul>
                        <li><b>Token Staking:</b> 8% of the total supply is dedicated to token holders. This staking option is designed to incentivize early adopters and will be rolled out within the first 6 months of the launch.</li>

                        <li><b>Land NFT Staking:</b> This involves staking land NFTs to earn yields. Land owners receive rewards for staking but must defend their land, as other stakers can attempt to take over.</li>
                    </ul>

                    <p>By staking, you not only grow your rewards but also contribute to the stability and success of the entire ĦBurn community, driving forward the shared goals of our ecosystem.</p>
                </div>
            </Modal>

            <Footer />
        </div>
    );
}

const mapDispatchToProps = {
    toggleLoader,
    getLottery,
    setAddress,
    whiteListNFT
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress, lotteryList } = Auth;
    return { publicAddress, lotteryList }
};

export default connect(mapStateToProps, mapDispatchToProps)(HBurnMarketplace);