import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import Spline from '@splinetool/react-spline';
import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";


import './index.css';
import { web3 } from '../../store/web3';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { toggleLoader, getLottery, setAddress } from "../../store/actions/Auth";
import { NFTABI, LotteryABI, CIFITokenABI } from '../../store/contract/index';


const DAO = (props) => {

    return (
        <div className="wrapper">
            <Navbar />

            <section className="dao-wrapper">
                <div className="container">
                    <div className="head">
                        <h1>DAO</h1>
                    </div>

                    <h3>Describe your DAO</h3>

                    <p>Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt. Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt.</p>
                    
                    <div className="form-group">
                        <input placeholder="DAO title" type="text" />
                    </div>

                    <div className="form-group">
                        <input placeholder="DAO description* (Max 250 characters)" type="text" />
                    </div>
                    
                    <h3>Setup shareholders email addresses</h3>

                    <p>Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt. Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt.</p>

                    <div class="checkbox-wrap">
                        <input type="checkbox" id="multi-gov"/>
                        <label for="multi-gov">Add multiple shareholders wallet addresses</label>
                    </div>

                    <div className="form-group">
                        <input placeholder="Founder email*" type="text" />
                    </div>

                    <h3>Configure your NFT collection</h3>

                    <p>Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt. Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt.</p>

                    <div className="form-group radio">
                        <div className="radio-wrap">
                            <p>
                                <input type="radio" id="create-nft" name="radio-group1" value="option1" checked/>
                                <label for="create-nft">
                                Create NFT
                                </label>
                            </p>

                            <p>
                                <input type="radio" id="own-nft" name="radio-group1" value="option2"/>
                                <label for="own-nft">
                                Already own NFT
                                </label>
                            </p>
                        </div>
                    </div>

                    <div className="form-group">
                        <input placeholder="Name*" type="text" />
                    </div>

                    <div className="form-group">
                        <input placeholder="Symbol*" type="text" />
                    </div>

                    {/* Type File */}

                    <div className="form-group">
                        <input placeholder="Base URI*" type="text" />
                    </div>

                    <div className="form-group">
                        <input placeholder="Price in HBAR*" type="number" />
                    </div>

                    <div className="form-group">
                        <input placeholder="Token wallet*" type="text" />
                    </div>

                    <div className="form-group">
                        <input placeholder="Payable token* (Leave empty for HBAR)" type="text" />
                    </div>

                    <div className="form-group">
                        <input placeholder="Total supply*" type="text" />
                    </div>

                    <div className="form-group">
                        <input placeholder="Description* (max 250 characters)" type="text" />
                    </div>

                    {/* <div className="dao-card">
                        <h3>Setup DAO rules</h3>
                        <p>Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt. Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt.</p>

                        <div class="checkbox-wrap">
                        <input type="checkbox" id="checkbox1" checked={!proposalOwner} name="proposalOwner" value={!proposalOwner} onChange={handleOnInput} />
                        <img src={subtabicon} alt="" className="" onClick={handleOnInput} />
                        <img src={subtabfillicon} className="fill" alt="" onClick={handleOnInput} />
                        <label for="checkbox1">Allow shareholders to submit proposal</label>

                        </div>

                        <div class="checkbox-wrap">
                        <input type="checkbox" id="checkbox2" checked={proposalOwner} name="proposalOwner" value={proposalOwner} onChange={handleOnInput} />
                        <img src={subtabicon} alt="" className="" onClick={handleOnInput} />
                        <img src={subtabfillicon} className="fill" alt="" onClick={handleOnInput} />
                        <label for="checkbox2">Owner can submit proposal only</label>
                        </div>
                    </div> */}

                    <h3>Configure your XDC 20 token</h3>

                    <p>Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt. Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt.</p>

                    <div className="form-group radio">
                        <div className="radio-wrap">
                            <p>
                                <input type="radio" id="create-token" name="radio-group2" value="option3" checked/>
                                <label for="create-token">
                                Create Token
                                </label>
                            </p>

                            <p>
                                <input type="radio" id="own-token" name="radio-group2" value="option4"/>
                                <label for="own-token">
                                Already own Token
                                </label>
                            </p>
                        </div>
                    </div>

                    <div className="form-group">
                        <input placeholder="Name*" type="text" />
                    </div>

                    <div className="form-group">
                        <input placeholder="Symbol*" type="text" />
                    </div>

                    {/* Type File */}

                    <div className="form-group">
                        <input placeholder="Total supply*" type="text" />
                    </div>

                    <div className="form-group">
                        <input placeholder="Description* (max 250 characters)" type="text" />
                    </div>

                    <button href="/mint-nft" className="common-btn" >Create DAO <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/create-dao.png" alt="" /></button>
                </div>
            </section>
            <Footer />
        </div>
    );
}

const mapDispatchToProps = {
    toggleLoader,
    getLottery,
    setAddress
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress, lotteryList } = Auth;
    return { publicAddress, lotteryList }
};

export default connect(mapStateToProps, mapDispatchToProps)(DAO);