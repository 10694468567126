import moment from "moment";
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import Spline from '@splinetool/react-spline';
import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactDOM from 'react-dom';


import './index.css';
import { web3 } from '../../store/web3';
import 'react-responsive-modal/styles.css';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { Modal } from 'react-responsive-modal';
import { toFixed } from "../../store/numberFormat";
import { networkId, message } from "../../store/config";
import { ERC20ABI } from '../../store/contract/index';
import { toggleLoader, getERC20, setAddress } from "../../store/actions/Auth";

const ViewToken = (props) => {

    let [data, setData] = useState({});
    let [collections, setCollections] = useState([]);
    let [walletAddress, setWalletAddress] = useState("");
    let [mintAmount, setMintAmount] = useState("");

    const [mintTokenModal, setMintTokenModal] = useState(false);

    const handleOnWheel = (event) => {
        const { type } = event.target;
        if (type === 'number') {
            event.preventDefault();
        }
    }

    setTimeout(() => {
        const modalRoot = document.querySelector('.react-responsive-modal-root');

        if (modalRoot) {
            //Add a custom class to the root element
            const stepsModal = modalRoot.querySelector('.steps-modal');

            if (stepsModal) {
                // Perform your action here
                modalRoot.classList.add('custom-modal-root');
            }

        } else {
            console.error("Element not found.");
        }
    }, 1000);

    useEffect(() => {
        props.getERC20();
        refreshBalance();
    }, []);

    async function refreshBalance() {
        if (typeof window.ethereum == "undefined") return EventBus.publish('error', "Metamask not available");
        // check network
        let netId = await web3.eth.net.getId()
        if (parseInt(netId) != parseInt(networkId)) {
            return EventBus.publish('info', message);
        }
        let address = (await web3.currentProvider.enable())[0];
        props.setAddress(address);
    }

    async function setMarketplaceData() {
        const updatedCollection = await Promise.all(
            props.erc20Collections.map(async (item) => {
                if (parseInt(item['network']) == parseInt(props.selectDefaultNetwok)) {
                    return { ...item };
                }
                return item;
            })
        );

        updatedCollection.sort((a, b) => {
            const nameA = a.name.toLowerCase(); // Convert names to uppercase for case-insensitive comparison
            const nameB = b.name.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0; // Names are equal
        });
        setCollections(updatedCollection);
    }

    useEffect(() => {
        if (props.publicAddress == null || props.publicAddress == undefined) {
            return;
        } else {
            if ((props.erc20Collections.length > 0) && web3) {
                setMarketplaceData();
            }
        }
    }, [props.erc20Collections, props.selectDefaultNetwok, props.publicAddress, web3]);

    async function callFilter(value) {
        let filterValue = props.erc20Collections.filter((a) => {
            if (value) {
                return a.name?.toString().toLowerCase().includes(value.toLowerCase())
            }
            else {
                return a
            }
        });
        setCollections(filterValue);
    }

    const handleImageError = (idx) => {
        // Set the failed image to the fallback image
        collections[idx]['banner'] = "https://ox-burn-assets.s3.us-east-2.amazonaws.com/dragon.gif";
        setCollections([...collections]);
    };

    const handleCopyAddress = (address) => {
        navigator.clipboard.writeText(address)
            .then(() => EventBus.publish("success", "Token Address Copied"))
            .catch((error) => console.log('Copy failed:', error));
    };

    async function setMinting(data) {
        setData(data);
        setMintTokenModal(true);
    }

    async function mintToken(e) {
        try {

            const waitFor = (delay) =>
                new Promise((resolve) => setTimeout(resolve, delay));

            let { publicAddress } = props;

            if (walletAddress == "") {
                EventBus.publish("error", `Enter wallet address!`);
                return;
            }

            if (!walletAddress.replace(/\s/g, '').length) {
                EventBus.publish("error", `Please enter wallet address`);
                return;
            }

            if (!walletAddress.match(/^[a-zA-Z0-9]+$/)) {
                EventBus.publish("error", `Invalid Wallet Address`);
                return;
            }

            if (mintAmount == "") {
                EventBus.publish("error", `Enter Token amount to mint!`);
                return;
            }

            if (publicAddress == null || publicAddress == undefined) {
                EventBus.publish("error", `Please connect your wallet!`);
                return;
            }

            let deployer = (await web3.currentProvider.enable())[0];

            const balanceWei = await web3.eth.getBalance(deployer);
            const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
            if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

            let contract = new web3.eth.Contract(ERC20ABI, data['address']);
            let minter = await contract.methods.owner().call();
            let minted = await contract.methods.totalSupply().call();
            let supply = await contract.methods.tokenSupply().call();

            if (props.publicAddress && props.publicAddress.toLowerCase() !== minter.toLowerCase()) {
                EventBus.publish("error", `Only owner can mint this token!`);
                return;
            }

            mintAmount = parseInt(mintAmount) * 10 ** 8;

            if ((parseInt(minted) + parseInt(mintAmount)) > supply) {
                EventBus.publish("error", `Mint amount exceeds available token supply that is ${(parseInt(supply) - parseInt(minted)) / 10 ** 8}!`);
                return;
            }

            props.toggleLoader({
                message: "Minting in Progress...",
                status: true,
            });

            await web3.eth
                .sendTransaction({
                    from: deployer,
                    to: data['address'],
                    data: contract.methods
                        .mintToken(walletAddress, mintAmount)
                        .encodeABI(),
                })
                .on('transactionHash', hash => {
                    console.log(`************** deploy contract hash = ${hash}`);
                })
                .on('receipt', async receipt => {
                    await refreshBalance();
                    setMintAmount("");
                    setWalletAddress("");
                    setData({});
                    props.toggleLoader({ status: false });
                    EventBus.publish("success", `Token(s) Minted Successfully!`);
                    setMintTokenModal(false);
                });
        } catch (e) {
            console.log(e);
            props.toggleLoader({
                message: "Mint Not Completed...",
                status: false,
            });
            setMintAmount("");
            setMintTokenModal(false);
            EventBus.publish("error", `Mint Failed`);
        }
    };

    async function handleChange(e) {
        const waitFor = (delay) =>
            new Promise((resolve) => setTimeout(resolve, delay));
        if ([e.target.name] == "mintAmount") {
            if (parseFloat(e.target.value) > 0) {
                setMintAmount(parseFloat(e.target.value));
            } else {
                setMintAmount("");
            }
        } else if ([e.target.name] == "walletAddress") {
            let input = e.target.value;
            let output = input.substring(0, 3); // checks first three char of address
            if (output == "xdc") {
                let result = "0x" + input.substring(3); // removes "xdc" and adds "0x" to the beginning
                setWalletAddress(result);
            } else {
                setWalletAddress(e.target.value);
            }
        }
    }

    // console.log("*** collections :: ", collections);

    return (
        <div className="wrapper">
            <Navbar />

            <section className="dao-wrapper view-token-wrap explore">
                <div className="container">
                    <div className="head view-token">
                        <h1>Explore Tokens</h1>

                        <div className="filter-wrap">
                            <div className="form-group search-wrap">
                                <input type="search" placeholder="Search Tokens" onChange={e => callFilter(e.target.value)} />
                                <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/search.png" alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {
                            collections?.length > 0 &&
                            collections?.map((item, idx) => (
                                <div class="col-md-6 col-lg-4 col-xl-3">
                                    <div class="card-box">
                                        <div class="card-inner">
                                            <img key={idx} src={item?.['banner'] ? item?.['banner'] : "https://ox-burn-assets.s3.us-east-2.amazonaws.com/dragon.gif"} onError={() => handleImageError(idx)} alt="Alternate Image" />

                                            <div class="card-body">
                                                <a class="card-left" href="/collection/296/0x9eb2c95b5a75b41953d6393615f1d12b956533ac">
                                                    {/* <img src="https://dev-0xburn-marketpalce-assets.s3.us-east-2.amazonaws.com/profile/5654770678-1723907876hburn.png" alt="Alternate Image" /> */}
                                                    <div class="card-right-text">
                                                        <span>{item['name']}</span>
                                                        <h4>{item['symbol']}</h4>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="wallet-address">
                                                <span>{item?.['address'] && item?.['address'].substring(0, 5) + '...' + item?.['address'].substring(37, item?.['address'].length)}</span>
                                                <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/document-copy.png" onClick={() => handleCopyAddress(item?.['address'])} />
                                            </div>
                                        </div>

                                        {
                                            props.publicAddress && props.publicAddress.toLowerCase() == item.owner.toLowerCase() &&
                                            <button className="common-btn" onClick={() => setMinting(item)}>Mint Token</button>
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
            <Footer />

            <Modal
                open={mintTokenModal}
                onClose={() => setMintTokenModal(false)}
                classNames={{
                    modal: `common-modal`
                }}
                center
            >
                <button className="common-close-btn">
                    <img
                        src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/close-modal.png"
                        onClick={() => setMintTokenModal(false)}
                    />
                </button>

                <div className="modal-body">
                    <h2>Mint Token</h2>

                    <div className="form-group">
                        <input
                            type="text"
                            name='walletAddress'
                            value={walletAddress}
                            maxlength="43"
                            placeholder="Wallet Address*"
                            onChange={handleChange}
                        />
                    </div>

                    <div className="form-group">
                        <input
                            onWheel={handleOnWheel}
                            type="number"
                            name='mintAmount'
                            value={mintAmount}
                            placeholder="Mint Amount*"
                            onChange={handleChange}
                        />
                    </div>

                    <button className="common-btn pr" onClick={() => mintToken()}>Mint<img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/submit.png" alt="" /></button>
                </div>
            </Modal>
        </div>
    );
}

const mapDispatchToProps = {
    getERC20,
    setAddress,
    toggleLoader
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress, erc20Collections } = Auth;
    return { publicAddress, erc20Collections }
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewToken);