import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { NavLink, Link, useParams } from "react-router-dom";
import moment from 'moment';
import Spline from '@splinetool/react-spline';
import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";


import './index.css';
import { web3 } from '../../store/web3';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import {NFTABI} from '../../store/contract/index';
import { toggleLoader, getCollection, getUser, getCollectionDetails, addRemoveFavItems } from "../../store/actions/Auth";

const alternate = "https://ox-burn-assets.s3.us-east-2.amazonaws.com/collection-img.png";

const Collection = (props) => {

    let { tokenAddress, chain } = useParams();
    let [maxSupply, setMaxSupply] = useState(0);
    let [totalMint, setTotalMint] = useState(0);
    let [price, setPrice] = useState(0);
    let [order, setOrder] = useState("asc");
    let [filter, setFilter] = useState("");
    let [handleChange, setHandleChange] = useState("");
    let [collection, setCollection] = useState();
    let [nfts, setNfts] = useState([]);

    const [itemPerRow, setItemPerRow] = useState(10);
    const [next, setNext] = useState(itemPerRow);

    useEffect(() => {
        props.toggleLoader({status:true,message:"Load collection"});
        props.getCollectionDetails({ tokenAddress, chain });
    }, [props.chain])

    const makeContract = async (contractAddress, type) => {
        let Token = new web3.eth.Contract(NFTABI, contractAddress);
            let totalMint = await Token.methods.totalSupply().call();
            let maxSupply = await Token.methods.maxSupply().call();
            let price = await Token.methods.priceRecipient().call();
            setMaxSupply(maxSupply);
            setTotalMint(totalMint);
            price = await web3.utils.fromWei(price.toString(), 'ether');
            setPrice(price);
    }

    useEffect(() => {
        if (Object.keys(props.singleCollectionDetails).length > 0) {
            let { collectionDetail, nfts } = props.singleCollectionDetails;
            setCollection(collectionDetail);
            if (collectionDetail.tokenType == "erc1155" && nfts.length > 0) {
                nfts = nfts.filter(item => item['mintAmount'] !== 0)
                setNfts(nfts);
            } else {
                setNfts(nfts);
            }
            makeContract(collectionDetail.tokenAddress, collectionDetail.tokenType);
        }
    }, [props.singleCollectionDetails,props.chain])

    const handleImageError = (idx) => {
        nfts[idx]['image'] = alternate;
        setNfts([...nfts]);
    };

    const handleUserImageError = (idx) => {
        nfts[idx]['users']['image'] = alternate;
        setNfts([...nfts]);
    };

    const AddFavItems = (nftId) => {
        props.addRemoveFavItems({ nftId, tokenAddress, chain });
    }

    const filterNft = async (e) => {
        e.preventDefault();
        setFilter(handleChange);
    }

    const copyAddress =()=> EventBus.publish('success',`address copy!`);

    const handleMoreImage = () => {
        setNext(next + itemPerRow);
    };

    
    return (
        <div className="wrapper">
            <Navbar />

            <section className="profile">
                <div className="container">
                    <div className="inner">
                        <div className="img-wrap">
                            <img src={collection && collection['profileImage'] ? collection['profileImage'] : alternate} alt="..." />
                        </div>

                        <div className="content">
                            <div className="upper">
                                <div >
                                    <h3>{collection && collection['collectionName'] ? collection['collectionName'] : ""}</h3>

                                    <p>{collection && collection['collectionDesc'] ? collection['collectionDesc'] : ""}</p>
                                </div>

                                <ul>
                                    {/* <li><a href=""><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/pr-facebook.png" alt="..." /></a></li>
                                    <li><a href=""><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/pr-twitter.png" alt="..." /></a></li>
                                    <li><a href=""><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/pr-google.png" alt="..." /></a></li>
                                    <li><a href=""><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/pr-chat.png" alt="..." /></a></li> */}

                                            <li>
                                                {collection && collection['website'] && collection['website'] !== "undefined"  &&
                                                    <a href={collection['website']}>
                                                        <img src={"https://ox-burn-assets.s3.us-east-2.amazonaws.com/pro-web.png"} alt="" />
                                                    </a>
                                                }
                                                </li>

                                                <li>

                                                {collection && collection['discord'] && collection['discord'] !== "undefined" &&
                                                    <a href={collection['discord']}>
                                                        <img src={"https://ox-burn-assets.s3.us-east-2.amazonaws.com/pro-discord.png"}  alt="" />
                                                    </a>
                                                }

                                                </li>

                                                <li>

                                                {collection && collection['instagram'] && collection['instagram'] !== "undefined" &&
                                                    <a href={collection['instagram']}>
                                                        <img src={"https://ox-burn-assets.s3.us-east-2.amazonaws.com/pro-insta.png"}  alt="" />
                                                    </a>
                                                }

                                                </li>

                                                <li>

                                                {collection && collection['medium'] && collection['medium'] !== "undefined" &&
                                                    <a href={collection['medium']}>
                                                        <img src={"https://ox-burn-assets.s3.us-east-2.amazonaws.com/pro-medium.png"}  alt="" />
                                                    </a>
                                                }

                                                </li>
                                                <li>

                                                {collection && collection['telegram'] && collection['telegram'] !== "undefined" &&
                                                    <a href={collection['telegram']}>
                                                        <img src={"https://ox-burn-assets.s3.us-east-2.amazonaws.com/send.png"}  alt="" />
                                                    </a>
                                                }
                                                </li>
                                </ul>
                                
                            </div>

                            <div className="bottom">
                                <div text={tokenAddress} >
                                <CopyToClipboard className="address" onCopy={copyAddress}>
                                    <span>{tokenAddress.slice(0,10)+'...'+tokenAddress.slice(36,tokenAddress.length)} <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/document-copy.png" alt="..." /></span>

                                </CopyToClipboard>
                                </div>
                                {/* <a className="common-btn" href="">EDIT PROFILE <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/btn-edit.png" alt="" /></a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="explore pr">
                <div className="container">
                    <div className="head text-center">
                        <h2>Owned NFT's</h2>
                    </div>

                    <div className="row">
                    {nfts.length > 0 && nfts && nfts.filter(item=>item.isMarketItem == true).slice(0, next).map((item, idx) =>
                        <div className="col-md-6 col-lg-4 col-xl-3">
                            <Link to={`/buy-nft/${item['_id']}`} className="card-box">
                                <div className="card-inner">
                                <img key={idx} src={item?.['image'] ? item?.['image'] : alternate} onError={() => handleImageError(idx)} alt="..." />

                                <h3>{item['title'] ? item['title'] : "-"}</h3>
                                    {/* <div class="card-body">
                                        <div className="card-left">
                                            <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/avatar.png" alt="" />
                                            <div className="card-right-text">
                                                <span>Creator</span>
                                                <h4>SalvadorDali</h4>
                                            </div>
                                        </div>
                                        <div className="card-right">
                                            <span>HBAR</span>
                                        </div>
                                    </div> */}
                                </div>

                                    <div className="card-bottom">
                                        <div className="price">
                                            <span className="current">Price</span>
                                            <p>
                                                <span className="bold">{item?.price} {item?.isRequiredHBurn ? " HBurn " : " HBAR "} </span>
                                            </p>
                                        </div>
                                        <div className="view-history">
                                          <a href=""> Token Id {item?.nftId} </a>
                                        </div>
                                    </div>
                            </Link>
                        </div>
                     )}
                    </div>
                    {next < nfts?.filter(item=>item.isMarketItem == true)?.length && (
                          <a className="common-btn" onClick={handleMoreImage}> Load More <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/btn-arrow.png" alt="" /></a>
                    )}
                    {/* <a className="common-btn" href="">Load More <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/btn-arrow.png" alt="" /></a> */}
                </div>
            </section>
            <Footer />
        </div>
    );
}

const mapDispatchToProps = { toggleLoader, getCollection, getUser, getCollectionDetails, addRemoveFavItems };

const mapStateToProps = ({ Auth }) => {
    let { isLogin, chain, publicAddress, singleCollectionDetails } = Auth;
    return { isLogin, chain, publicAddress, singleCollectionDetails }
}

export default connect(mapStateToProps, mapDispatchToProps)(Collection);