import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import React, { Component } from 'react';
import Error from '@material-ui/icons/Error';
import { createBrowserHistory } from "history";
import { ToastContainer, toast } from 'react-toastify';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { tokenVerify } from "../store/jwtVerify";

import { web3 } from "../store/web3";
import HBurnMarketplace from './HBurnMarketplace';
import Loader from '../components/loader';
import { networkId, message } from "../store/config";
import { logout, login } from '../store/actions/Auth';

import '../static/css/style.css';

import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import "react-toastify/dist/ReactToastify.css";
import SeedRound from "./SeedRound";
import Explore from "./Explore";
import MintNFT from "./MintNFT";
import PrivateSale from "./PrivateSale";
import DAO from "./DAO";
import DAOProposals from "./DAOProposals";
import BuyNFT from "./BuyNFT";
import EditProfile from "./EditProfile";
import Collection from "./Collection";
import Swap from "./Swap";
import Profile from "./Profile";
import Staking from "./Staking";
import CreateToken from "./CreateToken";
import ViewToken from "./ViewToken";
import Whitelist from "./Whitelist";

const hist = createBrowserHistory();
class App extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  };

  componentDidMount() {
    // this.checkEthereum();
    EventBus.on('tokenExpired', () => this.props.logout());
    if (!tokenVerify()) EventBus.on("tokenExpired", () => this.props.logout());
    EventBus.on('info', (e) => toast.info(() => <div> <Error /> {e}</div>));
    EventBus.on('error', (e) => toast.error(() => <div> <Error /> {e}</div>));
    EventBus.on('success', (e) => toast.success(() => <div> <CheckCircle /> {e}</div>));
  };


  // checkEthereum = async () => {
  //   if (typeof window.ethereum !== "undefined") {

  //     // check network
  //     web3.eth.net.getId((err, netId) => {
  //       if (netId != networkId) {
  //         EventBus.publish('info', message);
  //       }
  //     });

  //     window.ethereum.on("accountsChanged", accounts => {
  //       this.props.logout();
  //       EventBus.publish("info", "Account has been changed");
  //     });

  //     window.ethereum.on("networkChanged", netId => {
  //       this.props.logout();
  //       window.location.reload();
  //       EventBus.publish("info", "Network has been changed");
  //     });
  //   }
  // };

  render() {

    return (
      <div>
        <Loader />
        <ToastContainer
          closeOnClick
          position="bottom-left"
        />

        <Router history={hist}>
          <Switch>
            <Route exact path='/' component={props => <HBurnMarketplace {...props} />} />
            <Route exact path='/seed-round' component={props => <SeedRound {...props} />} />
            <Route exact path='/marketplace' component={props => <Explore {...props} />} />
            <Route exact path='/mint-nft' component={props => <MintNFT {...props} />} />
            <Route exact path='/private-sale' component={props => <PrivateSale {...props} />} />
            <Route exact path='/dao' component={props => <DAO {...props} />} />
            <Route exact path='/dao-proposals' component={props => <DAOProposals {...props} />} />
            <Route exact path='/buy-nft/:id' component={props => <BuyNFT {...props} />} />
            <Route exact path='/edit-profile' component={props => <EditProfile {...props} />} />
            <Route exact path='/collection/:chain/:tokenAddress' component={props => <Collection {...props} />} />
            <Route exact path='/profile' component={props => <Profile {...props} />} />
            <Route exact path='/swap' component={props => <Swap {...props} />} />
            <Route exact path='/staking' component={props => <Staking {...props} />} />
            <Route exact path='/create-token' component={props => <CreateToken {...props} />} />
            <Route exact path='/view-token' component={props => <ViewToken {...props} />} />
            {/* <Route exact path='/whitelist' component={props => <Whitelist {...props} />} /> */}
          </Switch>
        </Router>

      </div>
    );
  }
}

const mapDispatchToProps = {
  logout, login
};

const mapStateToProps = ({ Auth }) => {
  let { auth } = Auth;
  return { auth }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
