import EventBus from "eventing-bus";
import axios from 'axios';
import { connect } from 'react-redux';
import Spline from '@splinetool/react-spline';
import React, { useState, useEffect } from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { CopyToClipboard } from "react-copy-to-clipboard";

import './index.css';
import { web3 } from '../../store/web3';
import 'react-responsive-modal/styles.css';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { Modal } from 'react-responsive-modal';
import { networkId, message, networkIdEth } from "../../store/config";
import { toggleLoader, getInvesterData, saveInvesterData, setAddress, getPrice, getSeed, saveSeed, whiteListedSeedPhase } from "../../store/actions/Auth";
import { SeedRountAddress, USDTAddress, SeedRountEthAddress, SeedRountABI, USDTABI } from '../../store/contract/index';


const SeedRound = (props) => {
    const [value, setValue] = useState();
    const [isModel, setIsModel] = useState(0);
    const [countDown, setCountDown] = useState(true);
    const [visible, setVisible] = useState(false);
    const [address, setAddress] = useState("");

    let [mintModal, setMintModal] = useState(false);
    let [name, setName] = useState("");
    let [email, setEmail] = useState("");
    let [phone, setPhone] = useState("");

    let [whitelistModal, setWhitelistModal] = useState(false);
    let [whitelistName, setWhitelistName] = useState("");
    let [whitelistEmail, setWhitelistEmail] = useState("");
    let [whitelistPhone, setWhitelistPhone] = useState("");
    let [whiteListeAddres, setWhiteListAddress] = useState("");


    const checkETH = async () => {
        if (typeof window.ethereum != "undefined") {
            let deployer = (await web3.currentProvider.enable())[0];
            setAddress(deployer)
        }
    }

    useEffect(() => {
        props.getInvesterData()
        props.getPrice()
        props.getSeed()
        // checkETH()
    }, [])
    // },[window.ethereum])

    useEffect(() => {
        if (props && props.seeds && props.seeds.seeds) {
            setIsModel(Number(props.seeds.seeds))
        }
    }, [props.seeds])

    const buyUSDT = async () => {
        try {

            if (typeof window.ethereum == "undefined") return EventBus.publish('error', "Metamask not available");

            // check network
            let netId = await web3.eth.net.getId()
            if (netId != networkId) {
                // if (netId != networkIdEth) {
                return EventBus.publish('info', "please connect to the Etherum test network");
            }

            const USDTContract = new web3.eth.Contract(USDTABI, USDTAddress);
            const SeedRountContract = new web3.eth.Contract(SeedRountABI, SeedRountAddress);

            if (!name && !name.trim()) return EventBus.publish('error', `Please enter name`);
            if (!email && !email.trim()) return EventBus.publish('error', `Please enter email`);
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!regex.test(email)) return EventBus.publish('error', `Please enter valid email`);
            if (!phone && !phone.trim()) return EventBus.publish('error', `Please enter phone`);
            if (!value) return EventBus.publish('error', `Please enter amount`);

            if (value < 1) return EventBus.publish('error', `Amount is too low`);

            let deployer = (await web3.currentProvider.enable())[0];

            const balanceWei = await USDTContract.methods.balanceOf(deployer).call();
            const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
            if (Number(balanceEther) < 100) return EventBus.publish('error', "You can buy minimum 100 USDT");
            if (value < 100) return EventBus.publish('error', "You can buy minimum 100 USDT");
            if (Number(balanceEther) < value) return EventBus.publish('error', `You have only ${Number(balanceEther)} USDT`);

            let price = web3.utils.toWei(value.toString(), "ether");
            let receivedTokens = value / 0.2;

            props.toggleLoader({
                message: "Approvel in Progress...",
                status: true,
            });


            await web3.eth
                .sendTransaction({
                    from: deployer,
                    value: 0,
                    to: USDTAddress,
                    gas: 5000000,
                    data: USDTContract.methods
                        .approve(SeedRountAddress, price)
                        .encodeABI(),
                })
                .on('transactionHash', hash => {
                    console.log(`************** deploy contract hash = ${hash}`);
                })
                .on('receipt', async receipt => {

                    props.toggleLoader({
                        message: "Transaction in Progress...",
                        status: true,
                    });

                    await web3.eth
                        .sendTransaction({
                            from: deployer,
                            value: 0,
                            to: SeedRountAddress,
                            gas: 5000000,
                            data: SeedRountContract.methods
                                .buyUsingUSDT(deployer, price, web3.utils.toWei(receivedTokens.toString(), 'ether'))
                                .encodeABI(),
                        })
                        .on('transactionHash', hash => {
                            console.log(`************** deploy contract hash = ${hash}`);
                        })
                        .on('receipt', async receipt => {
                            props.saveInvesterData({ publicAddress: deployer, tokenAddress: SeedRountAddress, investedAmount: value, totalTokens: receivedTokens, name, email, phone })
                            props.saveSeed({ seeds: Number(props.seeds.seeds) + Number(value) })
                        });

                    setMintModal(false)

                    props.toggleLoader({
                        message: "Transaction in Progress...",
                        status: false,
                    });
                });

        } catch (error) {
            props.toggleLoader({
                message: "Transaction in Progress...",
                status: false,
            });
            return EventBus.publish('error', error.message);
        }
    }

    const buyARB = async () => {
        try {

            if (typeof window.ethereum == "undefined") return EventBus.publish('error', "Metamask not available");

            // check network
            let netId = await web3.eth.net.getId()
            if (netId != networkId) {
                return EventBus.publish('info', message);
            }

            const SeedRountContract = new web3.eth.Contract(SeedRountABI, SeedRountAddress);

            if (!name && !name.trim()) return EventBus.publish('error', `Please enter name`);
            if (!email && !email.trim()) return EventBus.publish('error', `Please enter email`);
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!regex.test(email)) return EventBus.publish('error', `Please enter valid email`);
            if (!phone && !phone.trim()) return EventBus.publish('error', `Please enter phone`);
            if (!value) return EventBus.publish('error', `Please enter amount`);

            if (value < 0) return EventBus.publish('error', `Amount is too low`);

            let deployer = (await web3.currentProvider.enable())[0];

            const balanceWei = await web3.eth.getBalance(deployer);
            const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
            let minimumAmount = (1 / Number(props.price.HBAR) * 100);
            minimumAmount = minimumAmount.toFixed(3);
            if (value < minimumAmount) return EventBus.publish('error', `You can buy minimum ${minimumAmount} HBAR`);
            if (Number(balanceEther) < value) return EventBus.publish('error', `You have only ${Number(balanceEther)} HBAR`);
            if (Number(minimumAmount) < 100) return EventBus.publish('error', "You can buy minimum 100 USDT");
            let investedAmount = (value * Number(props.price.HBAR)) * 5;
            investedAmount = investedAmount.toFixed(3)
            // let price = web3.utils.toWei(investedAmount.toString(), 'ether');
            // console.log("********** price",price)
            props.toggleLoader({
                message: "Transaction in Progress...",
                status: true,
            });

            await web3.eth
                .sendTransaction({
                    from: deployer,
                    value: web3.utils.toWei(value.toString(), 'ether'),
                    to: SeedRountAddress,
                    gas: 5000000,
                    data: SeedRountContract.methods
                        .buyUsingArbitrum(deployer, web3.utils.toWei(investedAmount.toString(), 'ether'))
                        .encodeABI(),
                })
                .on('transactionHash', hash => {
                    console.log(`************** deploy contract hash = ${hash}`);
                })
                .on('receipt', async receipt => {
                    props.saveInvesterData({ publicAddress: deployer, tokenAddress: SeedRountAddress, investedAmount: value, totalTokens: investedAmount, name, email, phone })
                    props.saveSeed({ seeds: Number(props.seeds.seeds) + Number(value) })
                });

            setMintModal(false)

            props.toggleLoader({
                message: "Transaction in Progress...",
                status: false,
            });

        } catch (error) {
            props.toggleLoader({
                message: "Transaction in Progress...",
                status: false,
            });
            return EventBus.publish('error', error.message);
        }
    }

    const buyETH = async () => {
        try {

            if (typeof window.ethereum == "undefined") return EventBus.publish('error', "Metamask not available");

            // check network
            let netId = await web3.eth.net.getId()
            if (netId != networkIdEth) {
                return EventBus.publish('info', "please connect to the Etherum test network");
            }

            const SeedRountContract = new web3.eth.Contract(SeedRountABI, SeedRountEthAddress);
            if (!name && !name.trim()) return EventBus.publish('error', `Please enter name`);
            if (!email && !email.trim()) return EventBus.publish('error', `Please enter email`);
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!regex.test(email)) return EventBus.publish('error', `Please enter valid email`);
            if (!phone && !phone.trim()) return EventBus.publish('error', `Please enter phone`);
            if (!value) return EventBus.publish('error', `Please enter amount`);

            if (value < 0) return EventBus.publish('error', `Amount is too low`);

            let deployer = (await web3.currentProvider.enable())[0];

            const balanceWei = await web3.eth.getBalance(deployer);
            const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
            let minimumAmount = (1 / Number(props.price.ETH) * 100);
            minimumAmount = minimumAmount.toFixed(3);
            if (value < minimumAmount) return EventBus.publish('error', `You can buy minimum ${minimumAmount} ETH`);
            if (Number(balanceEther) < value) return EventBus.publish('error', `You have only ${Number(balanceEther)} ETH`);
            if (Number(minimumAmount) < 100) return EventBus.publish('error', "You can buy minimum 100 USDT");
            let investedAmount = (value * Number(props.price.ETH)) * 5;
            investedAmount = investedAmount.toFixed(3)
            // let price = web3.utils.toWei(investedAmount.toString(), 'ether');
            // console.log("********** price",price)
            props.toggleLoader({
                message: "Transaction in Progress...",
                status: true,
            });

            await web3.eth
                .sendTransaction({
                    from: deployer,
                    value: web3.utils.toWei(value.toString(), 'ether'),
                    to: SeedRountAddress,
                    gas: 5000000,
                    data: SeedRountContract.methods
                        .buyUsingArbitrum(deployer, web3.utils.toWei(investedAmount.toString(), 'ether'))
                        .encodeABI(),
                })
                .on('transactionHash', hash => {
                    console.log(`************** deploy contract hash = ${hash}`);
                })
                .on('receipt', async receipt => {
                    props.saveInvesterData({ publicAddress: deployer, tokenAddress: SeedRountAddress, investedAmount: value, totalTokens: investedAmount, name, email, phone })
                    props.saveSeed({ seeds: Number(props.seeds.seeds) + Number(value) })
                });

            setMintModal(false)

            props.toggleLoader({
                message: "Transaction in Progress...",
                status: false,
            });

        } catch (error) {
            props.toggleLoader({
                message: "Transaction in Progress...",
                status: false,
            });
            return EventBus.publish('error', error.message);
        }
    }

    const submitWhitelistModel = async () => {
        try {

            if (!whitelistName && !whitelistName.trim()) return EventBus.publish('error', `Please enter name`);
            if (!whitelistEmail && !whitelistEmail.trim()) return EventBus.publish('error', `Please enter valid email`);
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!regex.test(whitelistEmail)) return EventBus.publish('error', `Please enter valid email`);
            if (!whitelistPhone && !whitelistPhone.trim()) return EventBus.publish('error', `Please enter phone`);
            if (!web3.utils.isAddress(whiteListeAddres)) return EventBus.publish('error', `Please enter valid wallet address`);

            props.whiteListedSeedPhase({ publicAddress: whiteListeAddres, name: whitelistName, email: whitelistEmail, phone: whitelistPhone });
            setWhitelistModal(false)

            props.toggleLoader({
                message: "Whitelisting in Progress...",
                status: true,
            });

        } catch (error) {
            props.toggleLoader({
                message: "Transaction in Progress...",
                status: false,
            });
            return EventBus.publish('error', error.message);
        }
    }

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            setCountDown(false);
        } else {
            // Render a countdown
            return (
                <div className="timer">
                    <p id="day">{zeroPad(days)}<span>DAYS</span></p>
                    <p id="hour">{zeroPad(hours)}<span>HOURS</span></p>
                    <p id="minute">{zeroPad(minutes)}<span>MINUTES</span></p>
                    <p id="second">{zeroPad(seconds)}<span>SECONDS</span></p>
                </div>
            )

        }
    };

    return (
        <div className="wrapper">
            <Navbar />
            {/* <MoonPayBuyWidget
                    variant="overlay"
                    baseCurrencyCode="usd"
                    baseCurrencyAmount="100"
                    defaultCurrencyCode="hbar"
                    // walletAddress="0x8db97C7cEcE249c2b98bDC0226Cc4C2A57BF52FC"
                    visible={visible}
            /> */}

            <section className="explore text-center vh">
                <h2>COMING SOON ON HEADSTARTER</h2>
                {/* <Countdown date={Date.now() + parseInt((Number(1725148800) * 1000) - Date.now())} renderer={renderer} /> */}
                {/* <button className="common-btn pr" onClick={() => setWhitelistModal(true)}>Get whitelisted<img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/buy.png" /></button> */}
            </section>

            {/* {countDown ?
            <section className="explore text-center vh">
                <h2>SEED ROUND STARTING IN</h2>
                <Countdown date={Date.now() + parseInt((Number(1725148800) * 1000) - Date.now())} renderer={renderer} />
                <button className="common-btn pr" onClick={()=>setWhitelistModal(true)}>Get whitelisted<img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/buy.png"/></button>
            </section>
            :
            <section className="buy-arbitrum">
                <div className="container">
                    <div className="head">
                        <span>10% Token Allocation</span>

                        <h1>Seed Round</h1>

                        <span className="wallet-address">Wallet Address</span>
                    </div>

                    <div className="progress-wrap">
                        <div className="info">
                            <span>{props?.seeds?.seeds ? props.seeds.seeds.toFixed(2) : 0}</span>

                            <span>150000.00</span>
                        </div>

                        <div class="progress">
                            <div class="progress-bar" role="progressbar" style={{width: props?.seeds.seeds ? (props.seeds.seeds/150000*100).toString()+"%" : "0%" }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>

                        <div className="bottom info">
                            <span>Sold</span>

                            <span>Remaining</span>
                        </div>
                    </div>
                    
                    <div className="form-group">
                        <input placeholder="Enter value" type="number" onChange={e=>setValue(e.target.value)} value={value}/>
                        <label>Price: 0.2 USD</label>
                    </div>

                    <button className="common-btn" onClick={()=>setMintModal(true)} disabled={isModel >= 150000 ? true : false}>Buy Now<img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/buy.png"/></button>

                    <div className="table-wrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Token Address</th>
                                        <th>Amount Invested</th>
                                        <th>HBurn Tokens</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.investerData && props.investerData.length > 0 && props.investerData.map(item=>
                                        <tr>
                                            <td>{item['publicAddress'] ? item['publicAddress'].slice(0,5)+"..."+item['publicAddress'].slice(30,item['publicAddress'].length):""}</td>
                                            <td>${item['investedAmount']}</td>
                                            <td>{item['totalTokens']}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                </div>
            </section>
            } */}
            <Footer />

            <Modal
                open={mintModal}
                onClose={() => setMintModal(false)}
                classNames={{
                    modal: `common-modal`
                }}
                center
            >
                <button className="common-close-btn">
                    <img
                        src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/close-modal.png"
                        onClick={() => setMintModal(false)}
                    />
                </button>

                <div className="modal-body">
                    <h2>Buy HBurn</h2>

                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Name"
                            onChange={e => setName(e.target.value)}
                            defaultValue={name}
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="email"
                            placeholder="Email"
                            onChange={e => setEmail(e.target.value)}
                            defaultValue={email}
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="tel"
                            placeholder="Phone"
                            onChange={e => setPhone(e.target.value)}
                            defaultValue={phone}
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="wallet address"
                            defaultValue={address}
                            disabled={true}
                        />
                    </div>

                    {/* <button className="common-btn mt-5" onClick={() => createProposal()}>Mint<img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/mint.png" alt="" /></button> */}
                    <button className="common-btn pr" onClick={buyUSDT} disabled={isModel >= 150000 ? true : false}>Buy From USDT <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/buy.png" /></button>
                    <button className="common-btn pr" onClick={buyARB} disabled={isModel >= 150000 ? true : false}>Buy From Hedera <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/buy.png" /></button>
                    <button className="common-btn pr" onClick={buyETH} disabled={isModel >= 150000 ? true : false}>Buy From ETH <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/buy.png" /></button>
                    {/* <a href="#" onClick={() => setVisible(!visible)}>if you don't have Hedera or Ethereum then buy from here.</a> */}
                </div>
            </Modal>

            {/* seed phase whitelisted model */}

            <Modal
                open={whitelistModal}
                onClose={() => setWhitelistModal(false)}
                classNames={{
                    modal: `common-modal`
                }}
                center
            >
                <button className="common-close-btn">
                    <img
                        src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/close-modal.png"
                        onClick={() => setWhitelistModal(false)}
                    />
                </button>

                <div className="modal-body">
                    <h2>Whitelist For HBurn</h2>

                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Name"
                            onChange={e => setWhitelistName(e.target.value)}
                            defaultValue={whitelistName}
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="email"
                            placeholder="Email"
                            onChange={e => setWhitelistEmail(e.target.value)}
                            defaultValue={whitelistEmail}
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="tel"
                            placeholder="Phone"
                            onChange={e => setWhitelistPhone(e.target.value)}
                            defaultValue={whitelistPhone}
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Wallet Address"
                            onChange={e => setWhiteListAddress(e.target.value)}
                            defaultValue={whiteListeAddres}
                        />
                    </div>

                    <button className="common-btn pr" onClick={submitWhitelistModel} > Submit Now <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/buy.png" /></button>
                </div>
            </Modal>
        </div>
    );
}

const mapDispatchToProps = {
    toggleLoader,
    getInvesterData,
    saveInvesterData,
    setAddress,
    getPrice,
    getSeed,
    saveSeed,
    whiteListedSeedPhase
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress, investerData, seeds, price } = Auth;
    return { publicAddress, investerData, seeds, price }
};

export default connect(mapStateToProps, mapDispatchToProps)(SeedRound);