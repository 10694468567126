import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";
import './index.css';
import { web3 } from '../../store/web3';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { networkId } from "../../store/config";
import { ERC20ABI, ERC20Bytecode } from '../../store/contract/index';
import { toggleLoader, uploadCollection, setAddress } from "../../store/actions/Auth";

const CreateToken = (props) => {

    let [balance, setBalance] = useState(0);
    let [tokenName, settokenName] = useState("");
    let [tokenSymbol, settokenSymbol] = useState("");
    let [tokenSupply, settokenSupply] = useState("");
    let [tokenBanner, settokenBanner] = useState("");
    let [tokenAddress, settokenAddress] = useState("");
    let [tokenDescription, settokenDescription] = useState("");

    const handleOnWheel = (event) => {
        const { type } = event.target;
        if (type === 'number') {
            event.preventDefault();
        }
    }

    useEffect(() => {
        document.addEventListener('wheel', handleOnWheel, { passive: false });

        return () => {
            document.removeEventListener('wheel', handleOnWheel);
        };
    }, []);

    setTimeout(() => {
        const modalRoot = document.querySelectorAll('.react-responsive-modal-root');
        if (modalRoot) {
            //Add a custom class to the root element

            modalRoot.forEach((element) => {
                const stepsModal = element.querySelector('.steps-modal');
                const confirmation1Modal = element.querySelector('.confirmation-modal');

                if (stepsModal) {
                    // Perform your action here
                    element.classList.add('custom-modal-root');
                }

                if (confirmation1Modal) {
                    // Perform your action here
                    element.classList.add('confirmation-modal-root');
                }
            });

        }
        else {
            console.error("Element not found.");
        }

    }, 1000);

    async function handleOnInput(e) {
        const waitFor = (delay) =>
            new Promise((resolve) => setTimeout(resolve, delay));
        if ([e.target.name] == "tokenName") {
            settokenName(e.target.value);
        } else if ([e.target.name] == "tokenSymbol") {
            settokenSymbol(e.target.value);
        } else if ([e.target.name] == "tokenDescription") {
            settokenDescription(e.target.value);
        } else if ([e.target.name] == "tokenSupply") {
            if (parseInt(e.target.value) > 0) {
                settokenSupply(parseInt(e.target.value));
            } else {
                settokenSupply("");
            }
        } else if ([e.target.name] == "tokenBanner") {
            settokenBanner(e.target.files[0]);
        }
    }

    useEffect(() => {
        if (web3 && props.publicAddress) {
            refreshBalance()
        }
    }, [web3, props.publicAddress])

    async function refreshBalance() {
        if (typeof window.ethereum == "undefined") return EventBus.publish('error', "Metamask not available");
        // check network
        let netId = await web3.eth.net.getId()
        if (parseInt(netId) != parseInt(networkId)) {
            return EventBus.publish('info', message);
        }
        let address = (await web3.currentProvider.enable())[0];
        props.setAddress(address);
        let balance = await web3.eth.getBalance(address); //Will give value in
        balance = web3.utils.fromWei(balance.toString(), "ether");
        let precision = 3;
        let power = Math.pow(10, precision);
        balance = parseFloat(balance);
        balance = Math.trunc(balance * power) / power;
        setBalance(balance);
    }

    async function deploy(e) {
        try {

            const waitFor = (delay) =>
                new Promise((resolve) => setTimeout(resolve, delay));

            let { publicAddress } = props;

            if (tokenName == "") {
                EventBus.publish("error", `Please enter token name`);
                return;
            }

            if (!tokenName.replace(/\s/g, '').length) {
                EventBus.publish("error", `Please enter token name`);
                return;
            }

            if (!tokenName.match(/[a-zA-Z]/)) {
                EventBus.publish("error", `Token name must contain alphabets`);
                return;
            }

            if (tokenSymbol == "") {
                EventBus.publish("error", `Please enter token symbol`);
                return;
            }

            if (!tokenSymbol.match(/[a-zA-Z]/)) {
                EventBus.publish("error", `Token symbol must contain alphabets`);
                return;
            }

            if (!tokenSymbol.replace(/\s/g, '').length) {
                EventBus.publish("error", `Please enter token symbol`);
                return;
            }

            if (tokenSupply == "") {
                EventBus.publish("error", `Please enter token supply`);
                return;
            }

            if (publicAddress == null || publicAddress == undefined) {
                EventBus.publish("error", `Please connect your wallet!`);
                return;
            }

            if (tokenDescription == "") {
                EventBus.publish("error", `Please enter token description`);
                return;
            }

            if (!tokenDescription.replace(/\s/g, '').length) {
                EventBus.publish("error", `Please enter token description`);
                return;
            }

            if (!tokenDescription.match(/[a-zA-Z]/)) {
                EventBus.publish("error", `Token description must contain alphabets`);
                return;
            }

            if (tokenBanner == "") {
                EventBus.publish("error", `Please upload token banner`);
                return;
            }

            if (tokenBanner == undefined) {
                EventBus.publish("error", `Please upload token banner`);
                return;
            }

            let from = publicAddress;
            let output = publicAddress.substring(0, 3); // removes "xdc" and adds "0x" to the beginning
            if (output == "xdc") {
                from = "0x" + publicAddress.substring(3);
            } else {
                from = publicAddress;
            }

            let deployer = (await web3.currentProvider.enable())[0];

            const balanceWei = await web3.eth.getBalance(deployer);
            const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
            // if (parseFloat(balanceEther) > 500) {
            //     let feeAmount = 500 * 10 ** 18;
            //     props.toggleLoader({
            //         message: "Fee Submission...",
            //         status: true,
            //     });
            //     await web3.eth
            //         .sendTransaction({
            //             from: deployer,
            //             to: "0x2FB9ceb2D4997569f4bB3d39c46A97083EE04c0C",
            //             value: feeAmount.toString()
            //         })
            //         .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
            //         .on('receipt', async receipt => {
            //         });
            // } else {
            //     return EventBus.publish("error", `Require 500 Hbar for token creation fee`);
            // }
            if (parseFloat(balanceEther) > 1) {
                let feeAmount = 1 * 10 ** 18;
                props.toggleLoader({
                    message: "Fee Submission...",
                    status: true,
                });
                await web3.eth
                    .sendTransaction({
                        from: deployer,
                        to: "0x2FB9ceb2D4997569f4bB3d39c46A97083EE04c0C",
                        value: feeAmount.toString()
                    })
                    .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
                    .on('receipt', async receipt => {
                    });
            } else {
                return EventBus.publish("error", `Require 500 Hbar for token creation fee`);
            }

            props.toggleLoader({
                message: "ERC20 Deployment in Progress...",
                status: true,
            });

            let tokenType = "ERC20"

            let supply = tokenSupply * 10 ** 8;

            let contract = new web3.eth.Contract(ERC20ABI);
            let deploy = contract.deploy({ data: ERC20Bytecode, arguments: [tokenName, tokenSymbol, supply] });

            await deploy.send({ from: deployer })
                .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
                .on('receipt', async receipt => {

                    settokenAddress(receipt['contractAddress']);
                    let tokenAddressDeployed = receipt['contractAddress'];
                    console.log("*** Deployed ERC20 Token Address :: ", tokenAddressDeployed);

                    let data = new FormData();
                    data.append('tokenName', tokenName);
                    data.append('tokenSymbol', tokenSymbol);
                    data.append('tokenDescription', tokenDescription);
                    data.append('tokenAddress', tokenAddressDeployed);
                    data.append('tokenSupply', tokenSupply);
                    data.append('tokenType', tokenType);
                    data.append('from', from);
                    data.append('banner', tokenBanner);
                    data.append('network', parseInt(networkId));
                    await props.uploadCollection(data);

                    EventBus.publish("success", `Deployment Complete`);

                    await refreshBalance();

                    waitFor(1500);
                    settokenName("");
                    settokenSymbol("");
                    settokenDescription("");
                    settokenSupply("");
                    settokenBanner("");
                    console.log(`************** deploy contract address = `, receipt['contractAddress'])
                });
        } catch (e) {
            console.log(e);
            props.toggleLoader({
                message: "Deployment Not Completed...",
                status: false,
            });
            EventBus.publish("error", `Unable To Deploy`);
        }
    };

    async function copiedAddress() {
        EventBus.publish("success", "Contract Address Copied");
    }

    return (
        <div>
            <Navbar />

            <section className="explore vh">
                <div className="container">
                    <div className="head text-center">
                        <h1>Create Token</h1>
                        {
                            tokenAddress !== "" &&
                            <>
                                <div className="copy-wrap">
                                    <p>
                                        <CopyToClipboard
                                            text={tokenAddress}
                                            onCopy={copiedAddress}
                                        >
                                            {networkId == 296 ? (
                                                <a href={`https://hashscan.io/testnet/contract/${tokenAddress}`} target="_blank">
                                                    {`Crypto Token Address:`}
                                                    <span> {tokenAddress}<img className="go-arrow" src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/btn-arrow.png" alt="Go Arrow" /></span>
                                                </a>
                                            ) : networkId == 295 ? (
                                                <a href={`https://hashscan.io/mainnet/contract/${tokenAddress}`} target="_blank">
                                                    {`Crypto Token Address:`}
                                                    <span> {tokenAddress}<img className="go-arrow" src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/btn-arrow.png" alt="Go Arrow" /></span>
                                                </a>
                                            ) : (
                                                <></>
                                            )}
                                        </CopyToClipboard>
                                    </p>

                                </div>
                                <p className="mb-4">You can visit view token page to view your token</p>
                            </>
                        }
                    </div>

                    <div className="form-group">
                        <input
                            type="text"
                            name='tokenName'
                            value={tokenName}
                            onChange={handleOnInput}
                            maxlength="42"
                            placeholder="Name*"
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="text"
                            name='tokenSymbol'
                            value={tokenSymbol}
                            onChange={handleOnInput}
                            maxlength="10"
                            placeholder="Symbol*"
                        />
                    </div>

                    <div className="form-group filewrap">
                        <div className="file-inner">
                            <span>{tokenBanner ? (tokenBanner['name'] && tokenBanner['name'].substring(0, 10) + '...') : "Upload Banner*"}</span>
                            <div className="upload-btn common-btn">Upload File</div>
                        </div>
                        <input
                            type="file"
                            name='tokenBanner'
                            placeholder={tokenBanner ? (tokenBanner['name'] && tokenBanner['name'].substring(0, 10) + '...') : "Upload Banner*"}
                            accept="image/*"
                            onChange={handleOnInput}
                        />
                    </div>

                    <div className="form-group">
                        <input
                            onWheel={handleOnWheel}
                            type="number"
                            name='tokenSupply'
                            value={tokenSupply}
                            onChange={handleOnInput}
                            placeholder="Total Supply*"
                        />
                    </div>

                    <div className="form-group textarea">
                        <textarea
                            as="textarea"
                            rows={3}
                            name='tokenDescription'
                            value={tokenDescription}
                            onChange={handleOnInput}
                            maxlength="250"
                            placeholder="Description* (Max 250 Characters)"
                        />
                    </div>

                    <button className="common-btn" onClick={() => deploy()}>Generate Token</button>
                </div>
                <br />
                <p>Note: You will need 500 HBAR fee to deploy your token.</p>
            </section>

            <Footer />
        </div>
    );
}

const mapDispatchToProps = {
    toggleLoader,
    uploadCollection,
    setAddress
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress } = Auth;
    return { publicAddress }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateToken);