import React, { useState, useEffect } from 'react';
import { NavLink, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import EventBus from 'eventing-bus';
import { web3 } from "../../store/web3";
import { networkId, networkType } from '../../store/config';
import { connectMetamask, getNetworkSymbol} from "../../store/walletConnect";
import { logout, setAddress, getNonce, login, setNonce} from '../../store/actions/Auth';

import '../../app/HBurnMarketplace/index.css';

const Footer = (props) => {
  let [login,setLogin] = useState(false);
  let [isSign,setIsSign] = useState(false);

  const handleWalletChanges = (network) => {
      if(network){
          connectMetamask(network, networkType)
          .then(async ({address}) => {
            if(address && !isSign){
                let chain = await web3.eth.getChainId();
                chain = web3.utils.hexToNumber(chain);
                chain = Number(chain);
                props.setAddress({publicAddress:address,chain});
                props.getNonce({publicAddress:address,chain});
                setIsSign(true)
              //   return EventBus.publish("success", `wallet connect successfuly`); 
            }
          })
          .catch((error) => {
            return EventBus.publish("error", `failed to connect wallet: ${error}`); 
        });
      }
  };

  const handleChainChange = async (chainId) => {
    const networkId = web3.utils.hexToNumber(chainId);
    const networkSymbol = await getNetworkSymbol(networkId.toString());
    if(!["hedera"].includes(networkSymbol)) return EventBus.publish("error", `Please Select Hedera`); 
    // if(networkSymbol !== props.network) props.setNetwork(networkSymbol);
    handleWalletChanges();
  };

  const handleWalletChange = async (accounts) => {
      handleWalletChanges();
  };

  const loginWallet = async(nonce) => {
      try {
        const accounts = await web3.eth.getAccounts();
        const address = accounts[0];
        if(address && !login){
          let chain = await web3.eth.getChainId();
          chain = web3.utils.hexToNumber(chain);
          chain = Number(chain);
          const signature = await web3.eth.personal.sign(`HBurn marketplace signature ${nonce}`, address);
          if(address && signature) props.login({ address, signature, nonce, chain})
          props.setNonce();
          setLogin(true);
        }
      } catch (error) {
          return EventBus.publish("error", `failed to create signature: ${error}`); 
      }
  }

  // useEffect(()=>{
  //     if(props.nonce) loginWallet(props.nonce);
  //     // if(props.nonce && props.isChangeNetwork === true) loginWallet(props.nonce);
  // },[props.nonce])

  // useEffect(()=>{
  //     if (typeof window.ethereum !== 'undefined') {
  //         // Register the event listener when the component mounts
  //         window.ethereum.on('chainChanged',()=>{
  //             setLogin(false)
  //             setIsSign(false)
  //             props.logout();
  //         });
  //         // Listen for accounts changed event
  //         window.ethereum.on('accountsChanged',()=>{
  //             setIsSign(false)
  //             props.logout();
  //         });
  //     }
  // },[]);

  // useEffect(() => {
  //     if (typeof window.ethereum !== 'undefined') {
  //       if (props.isChangeNetwork === true) {
  //         handleWalletChanges("hedera");
  //         setLogin(false);
  //       } 
  //     }
  // }, [props.isChangeNetwork]);
  
  return (
          <>
            {/* <button className="footer common-btn" onClick={()=>handleWalletChanges("hedera")}>{props?.publicAddress ? props.publicAddress.slice(0,6)+"..."+props.publicAddress.slice(38,42) : "Connect Wallet"}<img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/btn-arrow.png" alt="" /></button> */}

            <footer>
              <div className="container">
                <div className="inner">
                  <div className="copyright">
                    <p>Copyright © 2024 HBurn. All rights reserved.</p>
                  </div>

                  <ul className='social-icons'>
                    <li><a target="_blank" href="https://youtube.com/@hburn-hederagon?si=eDI-aEpfJQOWLwmK"><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/YoutubeLogo.svg" alt="" /></a></li>
                    <li><a target="_blank" href="https://discord.com/invite/6FpchyjBkP"><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/DiscordLogo.svg" alt="" /></a></li>
                    <li><a target="_blank" href="https://medium.com/@HBurnOfficial"><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/MediumLogo.svg" alt="" /></a></li>
                    <li><a target="_blank" href="https://x.com/HBurn_Official"><img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/XLogo.svg" alt="" /></a></li>
                  </ul>
                </div>
              </div>
            </footer>
            </>
        );
}

const mapDispatchToProps = { logout, setAddress, getNonce, login, setNonce };

const mapStateToProps = ({ Auth }) => {
  let { publicAddress, network, nonce, isLogin,isNonce, isChangeNetwork} = Auth;
  return { publicAddress, network, nonce, isLogin,isNonce, isChangeNetwork }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);