import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import Spline from '@splinetool/react-spline';
import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";

import Countdown, { zeroPad } from 'react-countdown';

import './index.css';
import { web3 } from '../../store/web3';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { Modal } from 'react-responsive-modal';
import { toggleLoader, getLottery, setAddress, setPatnerNFT } from "../../store/actions/Auth";
import { TokenABI, NFTAddress, NFTContract, DAOAddress, DAOContract } from '../../store/contract/index';
import { networkId, message } from "../../store/config";


const MintNFT = (props) => {

    const [totalMint, setTotalMint] = useState(0)
    const [totalSupply, setTotalSupply] = useState(0)
    const [ownerNFT, setOwnerNFTS] = useState([])
    const [address, setAddress] = useState()
    const [visible, setVisible] = useState(false);
    const [countDown, setCountDown] = useState(true);

    let [mintModal, setMintModal] = useState(false);
    let [name, setName] = useState("");
    let [email, setEmail] = useState("");
    let [phone, setPhone] = useState("");


    // useEffect(()=>{
    //     checkEthereum();
    // },[]);

    // const checkEthereum = async () => {
    //     try {
    //         if (typeof window.ethereum == "undefined") return EventBus.publish('error', "Metamask not available");

    //         // check network
    //         let netId = await web3.eth.net.getId()
    //         if (netId != networkId) {
    //             return EventBus.publish('info', message);
    //         }

    //         let address = (await web3.currentProvider.enable())[0];
    //         let owner = await NFTContract.methods.walletOfOwner(address).call();
    //         let totalMinted = await NFTContract.methods.totalSupply().call();
    //         let maxSupply = await NFTContract.methods.tokenSupply().call();
    //         setOwnerNFTS(owner);
    //         setAddress(address);
    //         setTotalSupply(maxSupply);
    //         setTotalMint(totalMinted);

    //     } catch (error) {
    //         return EventBus.publish('error', error.message);
    //     }

    // };

    // const mintNFT = async () => {
    //     try {

    //         if (typeof window.ethereum == "undefined") return EventBus.publish('error', "Metamask not available");

    //         let netId = await web3.eth.net.getId()
    //         if (netId != networkId) {
    //             return EventBus.publish('info', message);
    //         }

    //         if (ownerNFT.length >= 5) return EventBus.publish('error', `Max limit reached`);

    //         if (!name && !name.trim()) return EventBus.publish('error', `Please enter name`);
    //         if (!email && !email.trim()) return EventBus.publish('error', `Please enter email`);
    //         if (!phone && !phone.trim()) return EventBus.publish('error', `Please enter phone`);

    //         if (totalSupply == totalMint) {
    //             EventBus.publish('error', `All nft minted`);
    //             return;
    //         };

    //         /** Mint NFT **/

    //         let deployer = (await web3.currentProvider.enable())[0];

    //         const balanceWei = await web3.eth.getBalance(deployer);
    //         const balanceEther = web3.utils.fromWei(balanceWei, 'ether');
    //         if (balanceEther == 0) return EventBus.publish("error", `Insufficient balance for transaction`);

    //         let price = await NFTContract.methods.getPrice().call();
            
    //         // let price = 0;
    //         // let sentValue = (parseInt(price) * 1);
    //         // sentValue = web3.utils.toWei(sentValue.toString(), "ether");

    //         let TokenContract = new web3.eth.Contract(TokenABI, '0xB453D7Ea87C1269a79b88154a30AE7e63Ae5AD97');

    //         let tokenBalance = await TokenContract.methods.balanceOf(deployer).call({ from: deployer });

    //         if (parseFloat(price) > parseFloat(tokenBalance)) {
    //             return EventBus.publish("error", `Insufficient USDC tokens`);
    //         } else {
    //             props.toggleLoader({
    //                 message: "Approving Token...",
    //                 status: true,
    //             });
    //             await TokenContract.methods.approve(NFTAddress, price).send({ from: deployer }).then(console.log)
    //         }

    //         props.toggleLoader({
    //             message: "Minting in Progress...",
    //             status: true,
    //         });


    //         await web3.eth
    //             .sendTransaction({
    //                 from: deployer,
    //                 value: 0,
    //                 to: NFTAddress,
    //                 data: NFTContract.methods
    //                     .mint(deployer, 1, price)
    //                     .encodeABI(),
    //             })
    //             .on('transactionHash', hash => {
    //                 console.log(`************** deploy contract hash = ${hash}`);
    //             })
    //             .on('receipt', async receipt => {
    //                 checkEthereum();
    //                 props.setPatnerNFT({ name, email, phone, publicAddress: deployer.toLowerCase() })
    //                 setMintModal(false)

    //                 await web3.eth
    //                     .sendTransaction({
    //                         from: deployer,
    //                         value: 0,
    //                         to: DAOAddress,
    //                         data: DAOContract.methods
    //                             .addPartner(deployer)
    //                             .encodeABI(),
    //                     })
    //                     .on('transactionHash', hash => {
    //                         console.log(`************** deploy contract hash = ${hash}`);
    //                     })
    //                     .on('receipt', async receipt => {
    //                         checkEthereum();
    //                         props.toggleLoader({ status: false });
    //                         EventBus.publish("success", `NFT Minted Successfully!`);
    //                     });
    //             });

    //     } catch (e) {
    //         console.log('********Error = ', e);
    //         props.toggleLoader({ message: 'Minting not Completed...', status: false });
    //         EventBus.publish('error', `Unable to Mint NFT`);
    //     };
    // }

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            setCountDown(false);
        } else {
            // Render a countdown
            return (
                <div className="timer">
                    <p id="day">{zeroPad(days)}<span>DAYS</span></p>
                    <p id="hour">{zeroPad(hours)}<span>HOURS</span></p>
                    <p id="minute">{zeroPad(minutes)}<span>MINUTES</span></p>
                    <p id="second">{zeroPad(seconds)}<span>SECONDS</span></p>
                </div>
            )

        }
    };
    
    const targetDate = '2024-09-15T19:59:59';

    const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const difference = +new Date(targetDate) - +new Date();
      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        });
      } else {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    };

    const timer = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  // Dynamic styling based on time left
  // Calculate circular border completion percentage for each unit
  const getCompletionPercentage = (unit, max) => {
    return (unit / max) * 100;
  };

  // Dynamic styling for the circular border
  const getCircularStyle = (value, maxValue) => ({
    background: `conic-gradient(
      #07b2d4 ${getCompletionPercentage(value, maxValue)}%, 
      #1e3442 ${getCompletionPercentage(value, maxValue)}%
    )`,
  });
  
    return (
        <div className="wrapper">
            <Navbar />
            {countDown ?
             <section className="explore text-center vh">
                {/* <Countdown className="mb-5" date={Date.now() + parseInt((Number(1726412400) * 1000) - Date.now())} renderer={renderer} /> */}
                
                {/* <div className="countdown">
                    <div className="time-unit" style={getCircularStyle(timeLeft.days, 30)}>
                        <div className="inner-circle">
                        <p>{String(timeLeft.days).padStart(2, '0')}</p>
                        <span>Days</span>
                        </div>
                    </div>
                    <div className="time-unit" style={getCircularStyle(timeLeft.hours, 24)}>
                        <div className="inner-circle">
                        <p>{String(timeLeft.hours).padStart(2, '0')}</p>
                        <span>Hours</span>
                        </div>
                    </div>
                    <div className="time-unit" style={getCircularStyle(timeLeft.minutes, 60)}>
                        <div className="inner-circle">
                        <p>{String(timeLeft.minutes).padStart(2, '0')}</p>
                        <span>Minutes</span>
                        </div>
                    </div>
                    <div className="time-unit" style={getCircularStyle(timeLeft.seconds, 60)}>
                        <div className="inner-circle">
                        <p>{String(timeLeft.seconds).padStart(2, '0')}</p>
                        <span>Seconds</span>
                        </div>
                    </div>
                </div> */}
                <h1>COMING SOON</h1>
            </section>
            :
                <section className="mint-nft">
                    <div className="container">
                        <div className="head">
                            <span>Minting</span>

                            <h1>Mint HBurn NFT</h1>
                        </div>

                        <p>Mint HBurn partner NFTs to get exclusive rights to to HBurn project</p>

                        <div className="row">
                            <div className="col-lg-7">
                                <div className="mint-info-wrap">
                                    <div className="mint-info">
                                        <span>Total number of NFTs</span>

                                        <span>{totalSupply}</span>
                                    </div>

                                    <div className="mint-info">
                                        <span>Total Minted NFTs</span>
                                        <span>{totalMint}</span>
                                    </div>

                                    <div className="mint-info">
                                        <span>Price per NFT</span>

                                        <span>2500 USDT</span>
                                    </div>

                                    <div className="mint-info">
                                        <span>Connected address</span>

                                        <span>{NFTAddress ? NFTAddress.slice(0, 12) + "..." + NFTAddress.slice(31, 40) : ""}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="img-wrap">
                                    <video loop="true" autoplay="autoplay" controls="false" muted>
                                        <source src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/video2.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>

                        <button className="common-btn" onClick={() => setMintModal(true)} disabled={totalSupply == totalMint ? true : false}>Mint Now <img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/mint.png" alt="" /></button>
                    </div>
                </section>
            }

            <Footer />

            <Modal
                open={mintModal}
                onClose={() => setMintModal(false)}
                classNames={{
                    modal: `common-modal`
                }}
                center
            >
                <button className="common-close-btn">
                    <img
                        src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/close-modal.png"
                        onClick={() => setMintModal(false)}
                    />
                </button>

                <div className="modal-body">
                    <h2>Mint HBurn NFT</h2>

                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Name"
                            onChange={e => setName(e.target.value)}
                            defaultValue={name}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="email"
                            placeholder="Email"
                            onChange={e => setEmail(e.target.value)}
                            defaultValue={email}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="tel"
                            placeholder="Phone"
                            onChange={e => setPhone(e.target.value)}
                            defaultValue={phone}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="wallet address"
                            defaultValue={address}
                            disabled={true}
                        />
                    </div>


                    <button className="common-btn mt-5" onClick={() => mintNFT()}>Mint NFT<img src="https://ox-burn-assets.s3.us-east-2.amazonaws.com/mint.png" alt="" /></button>
                </div>
            </Modal>
        </div>
    );
}

const mapDispatchToProps = {
    toggleLoader,
    getLottery,
    setAddress,
    setPatnerNFT
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress, lotteryList } = Auth;
    return { publicAddress, lotteryList }
};

export default connect(mapStateToProps, mapDispatchToProps)(MintNFT);